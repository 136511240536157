import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NotFound() {
  const lang = useSelector((state) => state.app.lang);

  return (
    <div className="flex h-screen bg-gaming">
      <div className="m-auto">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src={
              lang === "en"
                ? "./assets/logo/Logo-En-Colored_256.png"
                : "./assets/logo/Logo-Ar-Colored_256.png"
            }
            alt={lang === "en" ? "Shilail Games" : "شليل للألعاب"}
          />
          <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-100">
            {lang === "en"
              ? "Sorry, page not found :("
              : "عذراً، الصفحة غير موجودة :("}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-400">
            {lang === "en"
              ? "The resource you're looking for does not exist or something went wrong."
              : "الصفحة التى تبحث عنها غير موجودة."}
          </p>
          <p className="mt-4 text-center text-sm text-gray-400">
            {lang === "en"
              ? "You can continue browsing"
              : "يمكنك مواصلة التصفح"}
          </p>
          <div className="mt-4">
            <Link
              to="/"
              className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
            >
              {lang === "en"
                ? "Back to home page"
                : "ألعودة الى الصفحة الرئيسية"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
