import {
  HeartIcon,
  HomeIcon,
  FireIcon,
  UserGroupIcon,
  UserCircleIcon,
  InformationCircleIcon,
  SupportIcon,
  ShieldCheckIcon,
  SparklesIcon,
} from "@heroicons/react/outline";

export const subscribedNavigationEn = [
  { name: "Home", href: "/", icon: HomeIcon },
  { name: "Profile", href: "/profile", icon: UserCircleIcon },
  // { name: "Friends", href: "/friends", icon: UserGroupIcon },
  { name: "Trending", href: "/trending", icon: FireIcon },
  { name: "Favorite", href: "/favorites", icon: HeartIcon },
];

export const subscribedNavigationAr = [
  { name: "الرئيسية", href: "/", icon: HomeIcon },
  { name: "الملف الشخصي", href: "/profile", icon: UserCircleIcon },
  // { name: "الأصدقاء", href: "/friends", icon: UserGroupIcon },
  { name: "الأكثر شعبية", href: "/trending", icon: FireIcon },
  { name: "المفضلة", href: "/favorites", icon: HeartIcon },
];

export const authenticatedNavigationEn = [
  { name: "Home", href: "/", icon: HomeIcon },
  { name: "Profile", href: "/profile", icon: UserCircleIcon },
  // { name: "Friends", href: "/friends", icon: UserGroupIcon },
  { name: "Trending", href: "/trending", icon: FireIcon },
  { name: "Favorite", href: "/favorites", icon: HeartIcon },
  { name: "Upgrade", href: "/subscribe", icon: SparklesIcon },
];

export const authenticatedNavigationAr = [
  { name: "الرئيسية", href: "/", icon: HomeIcon },
  { name: "الملف الشخصي", href: "/profile", icon: UserCircleIcon },
  // { name: "الأصدقاء", href: "/friends", icon: UserGroupIcon },
  { name: "الأكثر شعبية", href: "/trending", icon: FireIcon },
  { name: "المفضلة", href: "/favorites", icon: HeartIcon },
  { name: "الإشتراك", href: "/subscribe", icon: SparklesIcon },
];

export const guestNavigationEn = [
  { name: "Home", href: "/", icon: HomeIcon },
  { name: "Trending", href: "/trending", icon: FireIcon },
  { name: "Upgrade", href: "/subscribe", icon: SparklesIcon },
];

export const guestNavigationAr = [
  { name: "الرئيسية", href: "/", icon: HomeIcon },
  { name: "الأكثر شعبية", href: "/trending", icon: FireIcon },
  { name: "الإشتراك", href: "/subscribe", icon: SparklesIcon },
];

export const sideNavigationEn = [
  { name: "Help", href: "/help", icon: SupportIcon },
  // { name: "Conditions", href: "#", icon: ShieldCheckIcon },
  { name: "About", href: "/about", icon: InformationCircleIcon },
];

export const sideNavigationAr = [
  { name: "المساعدة", href: "/help", icon: SupportIcon },
  // { name: "الشروط و الأحكام", href: "#", icon: ShieldCheckIcon },
  { name: "عن المنصة", href: "/about", icon: InformationCircleIcon },
];

export const userNavigationEn = [
  // { name: "Your Profile", href: "/profile" },
  { name: "Settings", href: "/settings" },
];

export const userNavigationAr = [
  // { name: "ملفك الشخصي", href: "/profile" },
  { name: "الإعدادات", href: "/settings" },
];
