import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { clearError } from "./redux/actions/errorActions";
import ProtectedRoute from "./components/ProtectedRoute";
import SubscribedRoute from "./components/SubscribedRoute";
import HomePage from "./pages/HomePage";
import GamePlay from "./pages/GamePlay";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
// import Friends from "./pages/Friends";
import Login from "./pages/Auth/Login";
import Regsiter from "./pages/Auth/Register";
import Favorites from "./pages/Favorites/index";
import GameView from "./pages/GameView/index";
import Subscribe from "./pages/Subscribe/index";
import Trending from "./pages/Trending/index";
import ServerError from "./pages/Errors/ServerError";
import ErrorBoundary from "./components/ErrorBoundary";
import NotFound from "./pages/Errors/NotFound";
import Search from "./pages/Search/index";
import LoadingIcon from "./components/LoadingIcon";
import About from "./pages/About";
import Help from "./pages/Help";

export default function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { error, errorTitle, errorMessage } = useSelector(
    (state) => state.error
  );
  const { lang } = useSelector((state) => state.app);
  let navigate = useNavigate();

  // const arabicCss = document.createElement("link");
  // arabicCss.href = "/assets/css/arabic.css";
  // arabicCss.rel = "stylesheet";

  // const englishCss = document.createElement("link");
  // englishCss.href = "/assets/css/english.css";
  // englishCss.rel = "stylesheet";

  useEffect(() => {
    setLoading(true);
    if (lang) {
      if (lang === "ar") {
        document.documentElement.dir = "rtl";
        document.documentElement.lang = "ar";
        // document.head.appendChild(arabicCss);
      } else if (lang === "en") {
        document.documentElement.dir = "ltr";
        document.documentElement.lang = "en";
        // document.head.appendChild(englishCss);
      }
      // document.fonts.ready.then(() => {
      //   if (document.fonts.status === "loaded") {
      //     setLoading(false);
      //   }
      // });
      setLoading(false);
    }
  }, [lang]);

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
      <Transition.Root show={error} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => {
            dispatch(clearError());
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-dark rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-100"
                    >
                      {errorTitle}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">{errorMessage}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-800 shadow-sm px-4 py-2 bg-darker text-base font-medium text-white sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => {
                      dispatch(clearError());
                      navigate("/");
                    }}
                  >
                    {lang === "en" ? "Close" : "إغلاق"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Routes>
        <Route
          path="/game/:id"
          element={
            <ErrorBoundary>
              <GameView />
            </ErrorBoundary>
          }
        />
        <Route
          path="/game/:id/play"
          element={
            <ErrorBoundary>
              <GamePlay />
            </ErrorBoundary>
          }
        />
        <Route
          path="/favorites"
          element={
            <ProtectedRoute>
              <ErrorBoundary>
                <Favorites />
              </ErrorBoundary>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <ErrorBoundary>
                <Settings />
              </ErrorBoundary>
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/friends"
          element={
            <ProtectedRoute>
              <Friends />
            </ProtectedRoute>
          }
        /> */}

        <Route
          path="/subscribe"
          element={
            <ErrorBoundary>
              <Subscribe />
            </ErrorBoundary>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ErrorBoundary>
                <Profile />
              </ErrorBoundary>
            </ProtectedRoute>
          }
        />

        <Route
          path="/trending"
          element={
            <ErrorBoundary>
              <Trending />
            </ErrorBoundary>
          }
        />
        <Route
          path="/login"
          element={
            <ErrorBoundary>
              <Login />
            </ErrorBoundary>
          }
        />
        <Route
          path="/register"
          element={
            <ErrorBoundary>
              <Regsiter />
            </ErrorBoundary>
          }
        />
        <Route
          path="/search"
          element={
            <ErrorBoundary>
              <Search />
            </ErrorBoundary>
          }
        />
        <Route
          path="/about"
          element={
            <ErrorBoundary>
              <About />
            </ErrorBoundary>
          }
        />
        <Route
          path="/help"
          element={
            <ErrorBoundary>
              <Help />
            </ErrorBoundary>
          }
        />
        <Route path="/500" element={<ServerError />} />
        <Route path="/404" element={<NotFound />} />
        <Route
          path="/"
          element={
            <ErrorBoundary>
              <HomePage />
            </ErrorBoundary>
          }
        />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </>
  );
}
