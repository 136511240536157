import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";
import { changePassword } from "../../services/auth.service";
import { useSelector } from "react-redux";
import { clearUserData, responseErrorHandler } from "./../../utils/common";
import { useNavigate } from "react-router-dom";

export default function Security() {
  const lang = useSelector((state) => state.app.lang);

  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be 8 characters at minimum")
      .required("Password is required"),
    newPassword: Yup.string()
      .min(8, "New password must be 4 characters at minimum")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .min(8, "Username must be 2 characters at minimum")
      .required("Username is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  let navigate = useNavigate();

  const handleSubmit = (password, newPassword) => {
    setSubmitting(true);
    changePassword(password, newPassword)
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200) {
          setSuccessMessage(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  return (
    <React.Fragment>
      {/* Main content */}
      <div className="flex-1 xl:overflow-y-auto">
        <div className="mx-auto">
          <Formik
            initialValues={{
              password: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values) =>
              handleSubmit(values.password, values.newPassword)
            }
          >
            {({ touched, errors }) => (
              <Form className="mt-6 space-y-8">
                <div>
                  <h2
                    id="plan-heading"
                    className="text-xl leading-6 font-medium text-white text-opacity-50"
                  >
                    {lang === "en" ? "Security" : "الأمان"}
                  </h2>
                  <h5 className="text-sm leading-6 font-medium text-white text-opacity-20">
                    {lang === "en"
                      ? "Account security management"
                      : "ادارة امان الحساب"}
                  </h5>
                </div>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="old-password"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en"
                        ? "Current password"
                        : "كلمة المرور الحالية"}
                    </label>
                    <Field
                      type="password"
                      name="password"
                      id="old-password"
                      autoComplete="email"
                      className={`mt-1 block w-full rounded-md shadow-sm text-white sm:text-sm bg-dark ${
                        touched.password && errors.password
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="new-password"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "New password" : "كلمة المرور الجديدة"}
                    </label>
                    <Field
                      type="password"
                      name="newPassword"
                      id="new-password"
                      className={`mt-1 block w-full rounded-md shadow-sm text-white sm:text-sm bg-dark ${
                        touched.newPassword && errors.newPassword
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en"
                        ? "Confirm new password"
                        : "تأكيد كلمة المرور الجديدة"}
                    </label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      id="confirm-password"
                      className={`mt-1 block w-full rounded-md shadow-sm text-white sm:text-sm bg-dark ${
                        touched.confirmPassword && errors.confirmPassword
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>
                </div>

                <div className="pt-8 flex justify-end">
                  <button
                    type="submit"
                    className="ltr:ml-3 rtl:mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    {submitting ? (
                      <LoadingButtonIcon />
                    ) : lang === "en" ? (
                      "Save"
                    ) : (
                      "حفظ"
                    )}
                  </button>
                </div>
                {successMessage && (
                  <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                      <div className="p-2 rounded-lg bg-shilail-500 shadow-lg sm:p-3">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-shilail-700">
                              <CheckIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="ltr:ml-3 rtl:mr-3 font-medium text-white truncate">
                              <span className="md:hidden">
                                {lang === "en"
                                  ? "Password updated."
                                  : "تم تحديث كلمة المرور."}
                              </span>
                              <span className="hidden md:inline">
                                {lang === "en"
                                  ? "Password updated! your password has been successfully updated."
                                  : "تم التحديث! تم تحديث كلمة المرور بنجاح."}
                              </span>
                            </p>
                          </div>
                          <div className="order-2 flex-shrink-0 sm:order-3 ltr:sm:ml-2 rtl:sm:mr-2">
                            <button
                              type="button"
                              className="-mr-1 flex p-2 rounded-md hover:bg-shilail-400 focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setSuccessMessage(false)}
                            >
                              <span className="sr-only">
                                {lang === "en" ? "Dismiss" : "إخفاء"}
                              </span>
                              <XIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}
