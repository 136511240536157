import * as authService from "../../services/auth.service";

const loginAction = (payload) => ({ type: "LOGIN", payload });
const SubscriptionAction = (payload) => ({ type: "SUBSCRIPTION", payload });
const SubscriptionNotFoundAction = () => ({ type: "SUBSCRIPTION_NOT_FOUND" });
const updateUserAction = (payload) => ({ type: "UPDATE_USER", payload });
const invalidCredentialsAction = () => ({ type: "INVALID_CREDENTIALS" });
const emailAlreadyTaken = () => ({ type: "EMAIL_TAKEN" });

const logoutAction = () => ({ type: "LOGOUT" });

export const login = (phoneNumber, password) => (dispatch) => {
  authService
    .login(phoneNumber, password)
    .then((response) => {
      localStorage.setItem("accessToken", response.data.data.accessToken);
      dispatch(loginAction(response.data.data));
      if (response.data.data.subscriptions > 0)
        dispatch(SubscriptionAction(response.data.data.subscriptions[0]));
      else dispatch(SubscriptionNotFoundAction());
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          dispatch(invalidCredentialsAction());
        }
      }
    });
};

export const updateUser = (user) => (dispatch) => {
  dispatch(updateUserAction(user));
};

export const logout = () => (dispatch) => {
  dispatch(logoutAction());
  localStorage.clear();
};

export const register = (password, phoneNumber) => (dispatch) => {
  authService
    .register(phoneNumber, password)
    .then((response) => {
      localStorage.setItem("accessToken", response.data.data.accessToken);
      let user = response.data.data;
      if (!user.subscriptions) user.subscriptions = [];
      dispatch(loginAction(user));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 422) {
          dispatch(emailAlreadyTaken());
        }
      }
    });
};

// export const register = (userInfo) => (dispatch) => {
//   fetch(`http://localhost:4000/users`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//     body: JSON.stringify(userInfo),
//   })
//     .then((res) => res.json())
//     .then((data) => {
//       // data sent back will in the format of
//       // {
//       //     user: {},
//       //.    token: "aaaaa.bbbbb.bbbbb"
//       // }
//       localStorage.setItem("token", data.token);
//       dispatch(loginAction(data.user));
//     });
// };

export const clearState = () => ({ type: "CLEAR_STATE" });
