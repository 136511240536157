import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import ChoosePlan from "./ChoosePlan";
import CheckPhone from "./CheckPhone";
import Payment from "./Payment";
import Success from "./Success";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as subscribeActions from "../../redux/actions/subscribeActions";
import zainKsa from "../../assets/img/zain_logo.jpg";

export default function Subscribe() {
  const [steps, setSteps] = useState([
    {
      id: "01",
      nameEn: "Check ",
      nameAr: "التحقق",
      status: "current",
    },
    {
      id: "02",
      nameEn: "Choose Plan",
      nameAr: "اختيار الباقة",
      status: "upcoming",
    },
    { id: "03", nameEn: "Payment", nameAr: "الدفع", status: "upcoming" },
    { id: "04", nameEn: "Confirmation", nameAr: "التأكيد", status: "upcoming" },
  ]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const subscription = useSelector((state) => state.subscription);
  const lang = useSelector((state) => state.app.lang);

  const CheckedPhone = (enteredPhoneNumber, subscribed) => {
    setPhoneNumber(enteredPhoneNumber);
    if (subscribed) {
      dispatch(subscribeActions.subscribe(enteredPhoneNumber));
      navigate("/");
    } else {
      let newSteps = steps.map((step, stepIndex) =>
        stepIndex === 0
          ? { ...step, status: "complete" }
          : stepIndex === 1
          ? { ...step, status: "current" }
          : step
      );

      setSteps(newSteps);
    }
  };
  const choosePricing = (componentSelectedPricing) => {
    if (componentSelectedPricing) {
      setSelectedPricing(componentSelectedPricing);
      let newSteps = steps.map((step, stepIndex) =>
        stepIndex === 1
          ? { ...step, status: "complete" }
          : stepIndex === 2
          ? { ...step, status: "current" }
          : step
      );

      setSteps(newSteps);
    }
  };

  return (
    <React.Fragment>
      <main className="flex flex-col flex-1 lg:pt-16 bg-gaming">
        <div className="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0 backdrop-blur-lg shadow-lg bg-dark/25">
          <div className="pt-10 pb-16">
            <center>
              <img
                src={zainKsa}
                className="w-[180px] h-[180px] rounded-lg"
                alt="zain"
              />
            </center>
            <div className="px-6  mb-6">
              <h1 className="text-3xl font-extrabold text-white">
                {lang === "en" ? "Subscribe" : "الإشتراك"}
              </h1>
            </div>
            {subscription.subscription !== null ? (
              <div className="bg-dark shadow sm:rounded-lg mt-8">
                <div className="px-4 py-5 sm:p-6 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 mx-auto text-white mb-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                    />
                  </svg>
                  <h3 className="text-lg leading-6 font-medium text-white">
                    {lang === "en" ? "Subscribed!" : "مشترك مسبقاً!"}
                  </h3>
                  <div className="mt-2 sm:flex sm:items-center sm:justify-between text-center">
                    <div className="w-full text-sm text-white text-opacity-70 text-center">
                      <p className="text-center">
                        {lang === "en"
                          ? "You're subscribed to the platform, you can enjoy playing games."
                          : "انت مشترك مسبقاً في شليل."}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 sm:flex-shrink-0 sm:flex sm:items-center text-center">
                    <Link
                      to="/"
                      type="submit"
                      className="mx-auto inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 disabled:opacity-10"
                    >
                      {lang === "en" ? "Browse Games" : "تصفح الالعاب"}
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <nav aria-label="Progress">
                  <ol className="border border-gray-700 bg-dark rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                    {steps.map((step, stepIdx) => (
                      <li key={step.id} className="relative md:flex-1 md:flex">
                        {step.status === "complete" ? (
                          <div className="group flex items-center w-full">
                            <span className="px-6 py-4 flex items-center text-sm font-medium">
                              <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-shilail-500 rounded-full group-hover:bg-shilail-700">
                                <CheckIcon
                                  className="w-6 h-6 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                              <span className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-white">
                                {lang === "en" ? step.nameEn : step.nameAr}
                              </span>
                            </span>
                          </div>
                        ) : step.status === "current" ? (
                          <div
                            className="px-6 py-4 flex items-center text-sm font-medium"
                            aria-current="step"
                          >
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-shilail-500 rounded-full">
                              <span className="text-shilail-500">
                                {step.id}
                              </span>
                            </span>
                            <span className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-shilail-500">
                              {lang === "en" ? step.nameEn : step.nameAr}
                            </span>
                          </div>
                        ) : (
                          <div className="group flex items-center">
                            <span className="px-6 py-4 flex items-center text-sm font-medium">
                              <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-400 rounded-full group-hover:border-white">
                                <span className="text-gray-400 group-hover:text-white">
                                  {step.id}
                                </span>
                              </span>
                              <span className="ltr:ml-4 rtl:mr-4 text-sm font-medium text-gray-400 group-hover:text-white">
                                {lang === "en" ? step.nameEn : step.nameAr}
                              </span>
                            </span>
                          </div>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                          <>
                            {/* Arrow separator for lg screens and up */}
                            <div
                              className="hidden md:block absolute top-0 ltr:right-0 rtl:left-0 h-full w-5"
                              aria-hidden="true"
                            >
                              <svg
                                className="h-full w-full text-gray-700"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                              >
                                <path
                                  d="M0 -2L20 40L0 82"
                                  vectorEffect="non-scaling-stroke"
                                  stroke="currentcolor"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </>
                        ) : null}
                      </li>
                    ))}
                  </ol>
                </nav>
                <div className="mt-12">
                  {steps[0].status === "current" && (
                    <CheckPhone CheckedPhone={CheckedPhone} />
                  )}
                  {steps[1].status === "current" && (
                    <ChoosePlan choosePricing={choosePricing} />
                  )}
                  {steps[2].status === "current" && (
                    <Payment
                      productId={selectedPricing.productId}
                      enteredPhoneNumber={phoneNumber}
                    />
                  )}
                  {steps[3].status === "current" && <Success />}
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
