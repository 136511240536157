const defaultState = {
  subscription:
    localStorage.getItem("phoneNumber") && localStorage.getItem("subscribed")
      ? {
          phoneNumber: localStorage.getItem("phoneNumber"),
          subscribed: localStorage.getItem("subscribed"),
        }
      : null,
};

const subscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SUBSCRIPTION":
      return {
        subscription: { ...action.payload },
        error: null,
        subscribeDate: new Date(),
      };

    case "SUBSCRIPTION_NOT_FOUND":
      return {
        subscription: null,
        error: null,
        subscribeDate: null,
      };

    case "LOGOUT":
      return {
        subscription: null,
        error: null,
        subscribeDate: null,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
