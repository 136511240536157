import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as authActions from "../../redux/actions/authActions";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationIcon,
  ShieldExclamationIcon,
  XIcon,
} from "@heroicons/react/outline";

export default function Login() {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [submittingLogin, setSubmittingLogin] = useState(false);
  const [sessionTimeoutMessage, setSessionTimeoutMessage] = useState(true);

  const [searchParams] = useSearchParams();
  const logoutReason = searchParams.get("logout");

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^(([1]\d{8})|([9]\d{8}))$/, "Phone number is not valid")
      .required("Phone number is required"),
    password: Yup.string()
      .min(4, "Password must be 4 characters at minimum")
      .required("Password is required"),
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const authError = useSelector((state) => state.auth.error);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const lang = useSelector((state) => state.app.lang);

  const handleSubmit = (phoneNumber, password) => {
    setSubmittingLogin(true);
    dispatch(authActions.login(phoneNumber, password));
  };

  useEffect(() => {
    if (authError === "INVALID_CREDENTIALS") {
      setErrorModalOpen(true);
      setSubmittingLogin(false);
    }
  }, [authError]);

  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
  }, [authenticated, navigate]);

  const clearStateErrors = () => {
    dispatch(authActions.clearState());
  };

  return (
    <>
      <Transition.Root show={errorModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => {
            setErrorModalOpen(false);
            clearStateErrors();
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-dark rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-100"
                    >
                      {lang === "en" ? "Invalid Credentials" : "بيانات خاطئة"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        {lang === "en"
                          ? "Either the phone number or password provided are wrong."
                          : "رقم الهاتف أو كلمة المرور خطأ."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => {
                      setErrorModalOpen(false);
                      clearStateErrors();
                    }}
                  >
                    {lang === "en" ? "Close" : "إغلاق"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {logoutReason === "session-timeout" && sessionTimeoutMessage && (
        <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="p-2 rounded-lg bg-shilail-500 shadow-lg sm:p-3">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <span className="flex p-2 rounded-lg bg-shilail-700">
                    <ShieldExclamationIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <p className="ltr:ml-3 rtl:mr-3 font-medium text-white truncate">
                    <span className="md:hidden">
                      {lang === "en" ? "Session timeout." : "انتهت الجلسة."}
                    </span>
                    <span className="hidden md:inline">
                      {lang === "en"
                        ? "Please login again to browse games."
                        : "قم بتجسيل الدخول لتتمكن من تصفح الألعاب."}
                    </span>
                  </p>
                </div>
                <div className="order-2 flex-shrink-0 sm:order-3 ltr:sm:ml-2 rtl:sm:mr-2">
                  <button
                    type="button"
                    className="-mr-1 flex p-2 rounded-md hover:bg-shilail-400 focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setSessionTimeoutMessage(false)}
                  >
                    <span className="sr-only">
                      {lang === "en" ? "Dismiss" : "Yothx"}
                    </span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 app-bg">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-dark/75 backdrop-blur-md py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="sm:mx-auto sm:w-full sm:max-w-md mb-6">
              <img
                className="mx-auto h-24 w-auto"
                src={
                  lang === "en"
                    ? "./assets/logo/Logo-En-Colored_256.png"
                    : "./assets/logo/Logo-Ar-Colored_256.png"
                }
                alt={lang === "en" ? "Shilail Games" : "شليل للألعاب"}
              />
              <div className="flex items-center justify-center mt-2">
                <span className="text-xs text-gray-400">
                  {lang === "en" ? "Powered by" : "بالتعاون مع"}
                </span>
                <img
                  className="h-6 w-auto rtl:mr-2 ltr:ml-2"
                  src="/assets/logo/zain-logo.png"
                  alt={lang === "en" ? "Shilail Games" : "شليل للألعاب"}
                />
              </div>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-100">
                {lang === "en"
                  ? "Sign in to your account"
                  : "تسجيل الدخول الى الحساب"}
              </h2>
              <p className="mt-2 text-center text-sm text-gray-400">
                {lang === "en" ? "Or " : "أو "}
                <Link
                  to="/"
                  className="font-medium text-shilail-500 hover:text-shilail-400"
                >
                  {lang === "en"
                    ? "go back to home page"
                    : "العودة الى الصفحة الرئيسية"}
                </Link>
              </p>
            </div>

            <Formik
              initialValues={{ phoneNumber: "", password: "" }}
              validationSchema={LoginSchema}
              onSubmit={(values) =>
                handleSubmit(values.phoneNumber, values.password)
              }
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-100"
                    >
                      {lang === "en" ? "Phone number" : "رقم الهاتف"}
                    </label>
                    <div className="relative rounded-md shadow-sm text-white mt-1">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-white text-opacity-70 sm:text-sm">
                          +249
                        </span>
                      </div>
                      <Field
                        type="text"
                        name="phoneNumber"
                        id="phone-number"
                        className={`block w-full text-left pl-16 sm:pl-12 sm:text-sm rounded-md text-white bg-dark ${
                          touched.phoneNumber && errors.phoneNumber
                            ? "focus:ring-red-500 focus:border-red-500 border-red-700"
                            : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                        }`}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-100"
                    >
                      {lang === "en" ? "Password" : "كلمة المرور"}
                    </label>
                    <div className="mt-1">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className={`appearance-none block w-full px-3 py-2  rounded-md shadow-sm placeholder-gray-400 sm:text-sm bg-dark text-white ${
                          touched.password && errors.password
                            ? "border-red-500 focus:ring-red-500 focus:border-red-500 border-2"
                            : "border-gray-700 focus:ring-shilail-400 focus:border-shilail-400 focus:outline-none border"
                        }`}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {/* <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-shilail-500 focus:ring-shilail-400 border-gray-300 rounded"
                  /> */}
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-100"
                      >
                        {lang === "en" ? "Remember me" : "تذكرني"}
                      </label>
                    </div>

                    <div className="text-sm">
                      <Link
                        to="/"
                        className="font-medium text-shilail-500 hover:text-shilail-400"
                      >
                        {lang === "en"
                          ? "Forgot your password?"
                          : "نسيت كلمة المرور؟"}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 disabled:opacity-75"
                      disabled={submittingLogin}
                    >
                      {lang === "en" ? "Login" : "تسجيل الدخول"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-dark rounded-full text-gray-300">
                    {lang === "en"
                      ? "Don't have an account?"
                      : "ليس لديك حساب؟"}
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <Link
                  to="/register"
                  className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                >
                  {lang === "en" ? "Register Now" : "سجل الآن"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
