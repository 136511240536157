const genericErrorAction = (payload) => ({ type: "GENERIC_ERROR", payload });
const clearErrorAction = () => ({ type: "CLEAR_ERROR" });

export const genericError = (errorTitle, errorMessage) => (dispatch) => {
  dispatch(genericErrorAction({ errorTitle, errorMessage }));
};

export const clearError = () => (dispatch) => {
  dispatch(clearErrorAction());
};
