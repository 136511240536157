const operatorToken = process.env.REACT_APP_ZAIN_TOKEN;

export const guestHeader = () => {
  return { "x-api-key": operatorToken };
};

export const authHeader = () => {
  let accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    return {
      Authorization: "Bearer " + accessToken,
      "x-api-key": operatorToken,
    };
  } else {
    return { "x-api-key": operatorToken };
  }
};

export const authHeaderWithMultipart = () => {
  let accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    return {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "multipart/form-data",
      "x-api-key": operatorToken,
    };
  } else {
    return {
      "Content-Type": "multipart/form-data",
      "x-api-key": operatorToken,
    };
  }
};
