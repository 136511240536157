import {
  ArrowSmRightIcon,
  ArrowSmLeftIcon,
  DeviceMobileIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { checkSubscription } from "./../../services/subscribe.service";
import LoadingButtonIcon from "./../../components/LoadingButtonIcon";
import { useDispatch, useSelector } from "react-redux";
import { clearUserData, responseErrorHandler } from "./../../utils/common";
import * as subscribeActions from "../../redux/actions/subscribeActions";

export default function CheckPhone({ CheckedPhone }) {
  const lang = useSelector((state) => state.app.lang);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submittingPhoneNumber, setsubmittingPhoneNumber] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const phoneSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^(([5]\d{8}))$/, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const handleSubscribeSubmit = (submittedPhoneNumber) => {
    setPhoneNumber(submittedPhoneNumber);
    checkSubscription(submittedPhoneNumber)
      .then((response) => {
        if (response.status === 200) {
          console.log("response: ", response.status);
          //redirect to home as subscribed
          CheckedPhone(submittedPhoneNumber, true);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          CheckedPhone(submittedPhoneNumber, false);
        } else responseErrorHandler(error, lang);
      });
  };

  return (
    <div className="px-10 ">
      <Formik
        initialValues={{
          phoneNumber: "",
        }}
        validationSchema={phoneSchema}
        onSubmit={(values) => handleSubscribeSubmit(values.phoneNumber)}
      >
        {({ touched, errors }) => (
          <Form>
            <div className=" font-medium text-lg text-white">
              {lang === "en"
                ? "Fill form to check phone number"
                : "املأ حقول للتحقق من رقم الهاتف"}
            </div>
            <h5 className="text-sm leading-6 font-medium text-white text-opacity-80">
              {lang === "en"
                ? "To subscribe please fill all required fields"
                : "رجاءً اضف جميع الخانات المطلوبة"}
            </h5>

            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-white text-opacity-80"
                >
                  {lang === "en" ? "Phone number" : "رقم الهاتف"}
                </label>
                <div className="relative rounded-md shadow-sm text-white">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-white text-opacity-70 sm:text-sm text-left">
                      966
                    </span>
                  </div>
                  <Field
                    type="text"
                    name="phoneNumber"
                    id="phone-number"
                    className={`block w-full pl-16 sm:pl-12 sm:text-sm rounded-md text-left text-white bg-dark ${
                      touched.phoneNumber && errors.phoneNumber
                        ? "focus:ring-red-500 focus:border-red-500 border-red-700"
                        : "border-gray-700 focus:ring-shilail-400 focus:border-shilail-400"
                    }`}
                  />
                </div>
              </div>
            </div>

            <div className="bg-dark shadow sm:rounded-lg mt-8">
              <div className="px-4 py-5 sm:p-6">
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                  <h3 className="text-lg leading-6 font-medium text-white">
                    {lang === "en"
                      ? "Confirm phone number"
                      : "تأكيد رقم الهاتف"}
                  </h3>
                  <div className="mt-5 sm:mt-0 ltr:sm:ml-6 rtl:sm:mr-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 disabled:opacity-10"
                    >
                      {!submittingPhoneNumber ? (
                        <>
                          {lang === "en" ? "Confirm" : "تأكيد"}
                          {lang === "en" ? (
                            <ArrowSmRightIcon
                              className="ltr:-mr-1 ltr:ml-2 rtl:-ml-1 rtl:mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowSmLeftIcon
                              className="ltr:-mr-1 ltr:ml-2 rtl:-ml-1 rtl:mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </>
                      ) : (
                        <LoadingButtonIcon />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
