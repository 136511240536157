import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import { useSelector } from "react-redux";
import { getGame } from "./../../services/game.service";
import LoadingIcon from "../../components/LoadingIcon";
import { clearUserData, responseErrorHandler } from "./../../utils/common";
import { useNavigate } from "react-router-dom";

export default function GameView() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);

  const authenticated = useSelector((state) => state.auth.authenticated);
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);

  const apiUrl = process.env.REACT_APP_API_URL;

  let { id } = useParams();
  let navigate = useNavigate();

  const fetchGame = () => {
    getGame(id)
      .then((response) => {
        setGame(response.data.data);
        setLoading(false);
        navigate("/game/" + response.data.data.id + "/play");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  useEffect(() => {
    fetchGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navbar setSidebarOpen={setSidebarOpen} />
      {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
      <main className="flex flex-col justify-center flex-1 lg:pt-16 bg-gaming">
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-row justify-between bg-dark/25 backdrop-blur-lg shadow-lg">
            <div className="px-8 py-8 md:py-14 md:px-24 md:w-1/2 text-center ltr:md:text-left rtl:md:text-right">
              <h3 className="text-base font-bold text-primary">
                {lang === "en" ? game.category.name_en : game.category.name_ar}
              </h3>
              <h1 className="py-4 text-5xl font-bold text-white tracking-widest uppercase">
                {lang === "en" ? game.name_en : game.name_ar}
              </h1>
              <p className="text-white text-opacity-70 font-light">
                {lang === "en" ? game.description_en : game.description_ar}
              </p>
              {/* {authenticated ? (
                user.subscriptions.length > 0 ? (
                  <Navigate to={"/game/" + game.id + "/play"} replace={true} />
                ) : (
                  <Link
                    to="/subscribe"
                    className="tracking-wide my-4 md:my-6 inline-flex items-center px-8 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                  >
                    Upgrade to play
                  </Link>
                )
              ) : (
                <Link
                  to="/login"
                  className="tracking-wide my-4 md:my-6 inline-flex items-center px-8 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                >
                  Login to play
                </Link>
              )} */}
            </div>
            <div className="hidden md:block md:px-8 md:py-8 md:w-1/2">
              <div className="ltr:text-left rtl:text-right text-xs font-bold">
                <img
                  src={apiUrl + "/resources/images/games/" + game.image}
                  alt=""
                  className="h-80 w-80"
                />
              </div>
            </div>
          </div>
        )}
        {/* </Sidebar> */}
      </main>
    </React.Fragment>
  );
}
