import { ArrowSmRightIcon, ArrowSmLeftIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";
import {
  classNames,
  responseErrorHandler,
  clearUserData,
} from "../../utils/common";
import LoadingIcon from "./../../components/LoadingIcon";
import { RadioGroup } from "@headlessui/react";
import { useSelector } from "react-redux";
import { getPricings } from "../../services/profile.service";
import { useNavigate } from "react-router-dom";

export default function ChoosePlan({ choosePricing }) {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState(null);

  const lang = useSelector((state) => state.app.lang);
  let navigate = useNavigate();

  const fetchPricings = () => {
    setLoading(true);
    getPricings()
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setPlans(response.data.data.rows);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  useEffect(() => {
    fetchPricings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="px-10">
          <RadioGroup value={selectedPricing} onChange={setSelectedPricing}>
            <RadioGroup.Label className="text-base font-medium text-lg text-white">
              {lang === "en" ? "Select a plan" : "إختر خطة"}
            </RadioGroup.Label>

            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
              {plans.length > 0 ? (
                plans.map((pricing) => (
                  <RadioGroup.Option
                    key={pricing.id}
                    value={pricing}
                    className={({ checked, active }) =>
                      classNames(
                        checked ? "border-transparent" : "border-gray-500",
                        active ? "ring-2 ring-shilail-400" : "",
                        "relative bg-dark border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <div className="flex-1 flex">
                          <div className="flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-sm font-medium text-gray-100"
                            >
                              {lang === "en"
                                ? pricing.name_en
                                : pricing.name_ar}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="mt-1 flex items-center text-sm text-gray-400"
                            >
                              {lang === "en"
                                ? pricing.description_en
                                : pricing.description_ar}
                            </RadioGroup.Description>
                            <RadioGroup.Description
                              as="span"
                              className="mt-6 text-sm font-medium text-gray-100"
                            >
                              {pricing.amount}{" "}
                              {lang === "en" ? "SAR/ " : "ريال سعودي/ "}
                              <span className="capitalize">
                                {pricing.interval === "month"
                                  ? lang === "en"
                                    ? "Month"
                                    : "شهر"
                                  : pricing.interval === "year"
                                  ? lang === "en"
                                    ? "Year"
                                    : "سنة"
                                  : pricing.interval === "day"
                                  ? lang === "en"
                                    ? "Day"
                                    : "يوم"
                                  : pricing.interval === "week"
                                  ? lang === "en"
                                    ? "Week"
                                    : "اسبوع"
                                  : ""}
                              </span>
                            </RadioGroup.Description>
                          </div>
                        </div>
                        <CheckCircleIcon
                          className={classNames(
                            !checked ? "invisible" : "",
                            "h-5 w-5 text-shilail-500"
                          )}
                          aria-hidden="true"
                        />
                        <div
                          className={classNames(
                            active ? "border" : "border-2",
                            checked
                              ? "border-shilail-400"
                              : "border-transparent",
                            "absolute -inset-px rounded-lg pointer-events-none"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))
              ) : (
                <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg py-6 px-12 text-center col-span-1 sm:col-span-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                    />
                  </svg>
                  <span className="mt-2 block text-sm font-medium text-gray-400">
                    {lang === "en"
                      ? "No available plans yet"
                      : "لا يوجد خطط متاحة حالياً"}
                  </span>
                </div>
              )}
            </div>
          </RadioGroup>

          {plans.length > 0 && (
            <div className="mt-8 text-right">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 disabled:opacity-10"
                onClick={() => choosePricing(selectedPricing)}
              >
                {lang === "en" ? "Next" : "التالي"}
                {lang === "en" ? (
                  <ArrowSmRightIcon
                    className="ltr:-mr-1 ltr:ml-2 rtl:-ml-1 rtl:mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowSmLeftIcon
                    className="ltr:-mr-1 ltr:ml-2 rtl:-ml-1 rtl:mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
