import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import { classNames } from "./../../utils/common";
import Profile from "./Profile";
import Security from "./Security";
import Subscriptions from "./Subscriptions";

export default function Settings() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tabs, setTabs] = useState([
    { nameEn: "Profile", nameAr: "الملف الشخصي", current: true },
    { nameEn: "Subscriptions", nameAr: "الإشتراكات", current: true },
    { nameEn: "Security", nameAr: "الأمان", current: false },
  ]);

  const lang = useSelector((state) => state.app.lang);

  const changeTab = (tabIndex) => {
    let newTabs = tabs.map((tempTab, tempTabIndex) => {
      return tempTabIndex === tabIndex
        ? {
            nameAr: tempTab.nameAr,
            nameEn: tempTab.nameEn,
            current: true,
          }
        : {
            nameAr: tempTab.nameAr,
            nameEn: tempTab.nameEn,
            current: false,
          };
    });
    setTabs(newTabs);
  };

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Navbar setSidebarOpen={setSidebarOpen} />
      {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
      <main className="flex flex-col flex-1 lg:pt-16 bg-gaming">
        <div className="px-4 py-6 mx-2 md:mx-24 mt-4 rounded-lg backdrop-blur-lg shadow-lg bg-dark/25">
          {/* Content area */}
          <div className="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
            <main className="flex-1">
              <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
                <div className="pt-10 pb-16">
                  <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-3xl font-extrabold text-white">
                      {lang === "en" ? "Settings" : "الإعدادات"}
                    </h1>
                  </div>
                  <div className="px-4 sm:px-6 md:px-0">
                    <div className="py-6">
                      {/* Tabs */}
                      <div className="lg:hidden">
                        <label htmlFor="selected-tab" className="sr-only">
                          {lang === "en" ? "Select" : "إختر"}
                        </label>
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="mt-1 block w-full ltr:pl-3 ltr:pr-10 rtl:pr-3 rtl:pl-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                          defaultValue={
                            lang === "en"
                              ? tabs.find((tab) => tab.current).nameEn
                              : tabs.find((tab) => tab.current).nameAr
                          }
                        >
                          {tabs.map((tab) => (
                            <option key={tab.nameEn}>
                              {lang === "en" ? tab.nameEn : tab.nameAr}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden lg:block">
                        <div className="border-b border-gray-700">
                          <nav className="-mb-px flex space-x-8 rtl:space-x-reverse">
                            {tabs.map((tab, index) => (
                              <button
                                key={tab.nameEn}
                                type="button"
                                href={tab.href}
                                className={classNames(
                                  tab.current
                                    ? "border-primary text-primary"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-300",
                                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                onClick={() => changeTab(index)}
                              >
                                {lang === "en" ? tab.nameEn : tab.nameAr}
                              </button>
                            ))}
                          </nav>
                        </div>
                      </div>

                      {/* Main content */}
                      {tabs[0].current && <Profile />}
                      {tabs[0].current && <Subscriptions />}
                      {tabs[1].current && <Security />}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </main>
      {/* </Sidebar> */}
    </React.Fragment>
  );
}
