import React from "react";

export default function PreviewCoverPhotoImage({ file }) {
  const [preview, setPreview] = React.useState(null);

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };

  return (
    <div className="text-center">
      <img src={preview} alt="Preview" className="mx-auto h-12 w-24" />
    </div>
  );
}
