import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as authActions from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { checkPhoneNumber } from "../../services/auth.service";

export default function Regsiter() {
  const [submittingRegister, setSubmittingRegister] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const RegisterSchema = Yup.object().shape({
    // email: Yup.string()
    //   .email("Invalid email address format")
    //   .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^(([1]\d{8})|([9]\d{8}))$/, "Phone number is not valid")
      .required("Phone number is required")
      .test(
        "phoneNumberExists",
        "Phone number already used, login or use another number",
        async (phoneNumber) => !(await checkPhoneNumber(phoneNumber)).data.data
      ),
    // .test({
    //   name: "phoneNumberExists",
    //   message: "Phone number already used",
    //   test: async (phoneNumber) => {
    //     if (/^(([1]\d{8})|([9]\d{8}))$/.test(phoneNumber)) {
    //       return checkPhoneNumber(phoneNumber);
    //     } else {
    //       return false;
    //     }
    //   },
    // }),
    password: Yup.string()
      .min(4, "Password must be 4 characters at minimum")
      .required("Password is required"),
    // username: Yup.string()
    //   .min(2, "Username must be 2 characters at minimum")
    //   .required("Username is required"),
    // firstName: Yup.string()
    //   .trim()
    //   .min(2, "First name must be 4 characters at minimum")
    //   .required("First name is required"),
    // lastName: Yup.string()
    //   .trim()
    //   .min(2, "Last name must be 2 characters at minimum")
    //   .required("Last name is required"),
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const authError = useSelector((state) => state.auth.error);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const lang = useSelector((state) => state.app.lang);

  const handleSubmit = (values) => {
    setSubmittingRegister(true);
    dispatch(
      authActions.register(
        // values.firstName,
        // values.lastName,
        // values.email,
        values.password,
        // values.username,
        values.phoneNumber
      )
    );
  };

  useEffect(() => {
    if (authenticated) {
      navigate("/subscribe");
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (authError === "EMAIL_TAKEN") {
      setErrorModalOpen(true);
      setSubmittingRegister(false);
    }
  }, [authError]);

  const clearStateErrors = () => {
    dispatch(authActions.clearState());
  };

  return (
    <>
      <Transition.Root show={errorModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => {
            setErrorModalOpen(false);
            clearStateErrors();
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-dark rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-100"
                    >
                      {lang === "en"
                        ? "Email Already Taken"
                        : "البريد الإلكتروني مستخدم"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        {lang === "en"
                          ? "Kindly choose another email or login to your account."
                          : "استخدم بريد الكتروني آخر للتسجيل او سجل الدخول لحسابك"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => {
                      setErrorModalOpen(false);
                      clearStateErrors();
                    }}
                  >
                    {lang === "en" ? "Close" : "إغلاق"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto"
                src={
                  lang === "en"
                    ? "./assets/logo/Logo-En-Colored_256.png"
                    : "./assets/logo/Logo-Ar-Colored_256.png"
                }
                alt={lang === "en" ? "Shilail Games" : "شليل للألعاب"}
              />
              <div className="flex items-center mt-2">
                <span className="text-xs text-gray-400">
                  {lang === "en" ? "Powered by" : "بالتعاون مع"}
                </span>
                <img
                  className="h-6 w-auto rtl:mr-2 ltr:ml-2"
                  src="/assets/logo/zain-logo.png"
                  alt={lang === "en" ? "Shilail Games" : "شليل للألعاب"}
                />
              </div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-100">
                {lang === "en" ? "Silail Registration" : "التسجيل في شليل"}
              </h2>
              <p className="mt-2 text-sm text-gray-400">
                {lang === "en" ? "Or " : "أو "}
                <Link
                  to="/"
                  className="font-medium text-shilail-500 hover:text-shilail-400"
                >
                  {lang === "en"
                    ? "go back to home page"
                    : "العودة الى الصفحة الرئيسية"}
                </Link>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div>
                  <p className="text-sm font-medium text-gray-300">
                    {lang === "en" ? "Already have an account?" : "لديك حساب؟ "}
                  </p>

                  <div className="mt-2">
                    <Link
                      to="/login"
                      className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                    >
                      {lang === "en" ? "Login" : "تسجيل الدخول"}
                    </Link>
                  </div>
                </div>

                <div className="mt-6 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-500" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-darker text-gray-500">
                      {lang === "en" ? "Or continue with" : "أو مواصلة التسجيل"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <Formik
                  initialValues={{
                    // email: "",
                    password: "",
                    // firstName: "",
                    // lastName: "",
                    phoneNumber: "",
                    // username: "",
                  }}
                  validationSchema={RegisterSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ touched, errors }) => (
                    <Form className="space-y-2">
                      {/* <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-100"
                        >
                          {lang === "en"
                            ? "Email address"
                            : "البريد الإلكتروني"}
                        </label>
                        <div className="mt-1">
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm bg-dark text-white ${
                              touched.email && errors.email
                                ? "focus:ring-red-500 focus:border-red-500 border-red-500 border-2"
                                : "focus:ring-shilail-400 focus:border-shilail-400 border-gray-700"
                            }`}
                          />
                        </div>
                      </div> */}

                      {/* <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12 sm:col-span-6">
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium text-gray-100"
                          >
                            {lang === "en" ? "First name" : "الاسم الاول"}
                          </label>
                          <div className="mt-1">
                            <Field
                              id="firstName"
                              name="firstName"
                              type="text"
                              className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm bg-dark text-white ${
                                touched.firstName && errors.firstName
                                  ? "focus:ring-red-500 focus:border-red-500 border-red-500 border-2"
                                  : "focus:ring-shilail-400 focus:border-shilail-400 border-gray-700"
                              }`}
                            />
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6">
                          <label
                            htmlFor="lastName"
                            className="block text-sm font-medium text-gray-100"
                          >
                            {lang === "en" ? "Last name" : "الاسم الاخير"}
                          </label>
                          <div className="mt-1">
                            <Field
                              id="lastName"
                              name="lastName"
                              type="text"
                              className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm bg-dark text-white ${
                                touched.lastName && errors.lastName
                                  ? "focus:ring-red-500 focus:border-red-500 border-red-500 border-2"
                                  : "focus:ring-shilail-400 focus:border-shilail-400 border-gray-700"
                              }`}
                            />
                          </div>
                        </div>
                      </div> */}

                      <div className="space-y-1">
                        <label
                          htmlFor="phoneNumber"
                          className="block text-sm font-medium text-gray-100"
                        >
                          {lang === "en" ? "Phone number" : "رقم الهاتف"}
                        </label>
                        <div className="relative rounded-md shadow-sm text-white mt-1">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-white text-opacity-70 sm:text-sm">
                              +249
                            </span>
                          </div>
                          <Field
                            type="text"
                            name="phoneNumber"
                            id="phone-number"
                            className={`block w-full pl-16 sm:pl-12 sm:text-sm border rounded-md text-left text-white bg-dark ${
                              touched.phoneNumber && errors.phoneNumber
                                ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                            }`}
                          />
                        </div>
                        <div className="text-sm text-red-500">
                          {errors.phoneNumber === "Phone number is not valid"
                            ? lang === "en"
                              ? errors.phoneNumber
                              : "الرقم غير صالح ، اكتب الرقم من غير 0 في البداية"
                            : errors.phoneNumber === "Phone number is required"
                            ? lang === "en"
                              ? errors.phoneNumber
                              : "ادخل رقم الهاتف"
                            : errors.phoneNumber ===
                              "Phone number already used, login or use another number"
                            ? lang === "en"
                              ? errors.phoneNumber
                              : "الرقم مستخدم مسبقاً ، سجل الدخول او استخدم رقم آخر"
                            : errors.phoneNumber}
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-100"
                        >
                          {lang === "en" ? "Password" : "كلمة المرور"}
                        </label>
                        <div className="mt-1">
                          <Field
                            id="password"
                            name="password"
                            type="password"
                            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm bg-dark text-white ${
                              touched.password && errors.password
                                ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                : "focus:ring-shilail-400 focus:border-shilail-400 border-gray-700"
                            }`}
                          />
                        </div>
                        <div className="text-sm text-red-500">
                          {errors.password === "Password is required"
                            ? lang === "en"
                              ? errors.password
                              : "يجب اختيار كلمة مرور"
                            : errors.password ===
                              "Password must be 4 characters at minimum"
                            ? lang === "en"
                              ? errors.password
                              : "كلمة المرور يجب ان تكون 4 حروف على الأقل"
                            : errors.password}
                        </div>
                      </div>

                      {/* <div className="space-y-1">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-100"
                        >
                          {lang === "en" ? "Username" : "اسم المستخدم"}
                        </label>
                        <div className="mt-1">
                          <Field
                            id="username"
                            name="username"
                            type="text"
                            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm bg-dark text-white ${
                              touched.username && errors.username
                                ? "focus:ring-red-500 focus:border-red-500 border-red-500 border-2"
                                : "focus:ring-shilail-400 focus:border-shilail-400 border-gray-700"
                            }`}
                          />
                        </div>
                      </div> */}

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 disabled:opacity-50"
                          disabled={submittingRegister}
                        >
                          {lang === "en" ? "Register" : "التسجيل"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/assets/images/colorful-background.jpg"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
