import { store } from "../redux/store";
import { genericError } from "../redux/actions/errorActions";
import { logout } from "../redux/actions/authActions";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
export const MAX_AVATAR_FILE_SIZE = 2 * 1024 * 1024;
export const MAX_COVERPHOTO_FILE_SIZE = 4 * 1024 * 1024;

export const responseErrorHandler = (error, lang = "ar") => {
  store.dispatch(
    genericError(
      lang === "en" ? "Error!" : "حدث خطأ!",
      error
        ? error
        : lang === "en"
        ? "An error occured while fetching data from the servers. Kindly try again later."
        : "حدث خطأ اثناء جلب البيانات من الخادم. رجاءً حاول مرة اخرى لاحقاً."
    )
  );
};

export const clearUserData = () => {
  store.dispatch(logout());
};
