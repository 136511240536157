const defaultState = {
  lang: "ar",
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "LANG_AR":
      return {
        lang: "ar",
      };

    case "LANG_EN":
      return {
        lang: "en",
      };

    default:
      return state;
  }
};

export default appReducer;
