import axios from "axios";
import { authHeader } from "../utils/requestHeaders";

const apiUrl = process.env.REACT_APP_API_URL;

export const requestSubscribe = (phoneNumber, productId) => {
  return axios.get(
    apiUrl + "/ksa-dsp/request-subscribe/" + phoneNumber + "/" + productId
  );
};

export const subscribe = (phoneNumber, productId, otp) => {
  return axios.get(
    apiUrl + "/ksa-dsp/subscribe/" + phoneNumber + "/" + productId + "/" + otp
  );
};

export const checkSubscription = (phoneNumber) => {
  var request = axios.get(apiUrl + "/ksa-dsp/check/" + phoneNumber);

  return request;
};

export const cancelSubscription = (phoneNumber) => {
  return axios.delete(apiUrl + `/ksa-dsp/cancel-subscription/${phoneNumber}`, {
    headers: authHeader(),
  });
};
