import * as subscribeService from "../../services/subscribe.service";

const checkSubscriptionAction = (payload) => ({
  type: "SUBSCRIPTION",
  payload,
});

const NotFoundAction = () => ({ type: "SUBSCRIPTION_NOT_FOUND" });

const resetSubscriptionAction = () => ({ type: "LOGOUT" });

const subscripedAction = (payload) => ({ type: "SUBSCRIPTION", payload });

export const checkSubscription = (phoneNumber) => (dispatch) => {
  subscribeService
    .checkSubscription(phoneNumber)
    .then((response) => {
      if (response.data.data.active) {
        localStorage.setItem("phoneNumber", phoneNumber);
        localStorage.setItem("subscribed", true);
        dispatch(checkSubscriptionAction({ phoneNumber, subscribed: true }));
      } else dispatch(NotFoundAction());
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          dispatch(NotFoundAction());
        }
      }
    });
};

export const subscribe = (phoneNumber) => (dispatch) => {
  localStorage.setItem("phoneNumber", phoneNumber);
  localStorage.setItem("subscribed", true);
  dispatch(subscripedAction({ phoneNumber, subscribed: true }));
  localStorage.clear();
};

export const unSubscribe = () => (dispatch) => {
  dispatch(resetSubscriptionAction());
  localStorage.clear();
};

export const logout = () => (dispatch) => {
  dispatch(resetSubscriptionAction());
  localStorage.clear();
};
