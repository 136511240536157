import React, { useState, useEffect } from "react";
import Navbar from "../../partials/navbar";
// import Sidebar from "../../partials/sidebar";
import { classNames, responseErrorHandler } from "../../utils/common";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import * as gameServices from "./../../services/game.service";
import LoadingIcon from "../../components/LoadingIcon";

export default function Trending() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const [games, setGames] = useState([]);
  const [categories, setCategories] = useState([]);

  const lang = useSelector((state) => state.app.lang);

  const apiUrl = process.env.REACT_APP_API_URL;

  const listAllGames = () => {
    gameServices
      .listGames()
      .then((response) => {
        setLoadingGames(false);
        if (response.status === 200) {
          let listedGames = response.data.data.rows;

          let listedCategories = listedGames.map((game) => game.category);
          let removedDuplicatedCategories = listedCategories.filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );
          removedDuplicatedCategories.map(
            (category) => (category.active = false)
          );
          removedDuplicatedCategories.unshift({
            id: 0,
            name_en: "All games",
            name_ar: "جميع الألعاب",
            active: true,
          });
          setCategories(removedDuplicatedCategories);
          setGames(listedGames);
        }
      })
      .catch((error) => responseErrorHandler(error, lang));
  };

  const getGamesByCategory = (categoryId) => {
    gameServices
      .listGamesByCategory(categoryId)
      .then((response) => {
        setLoadingGames(false);
        if (response.status === 200) {
          let listedGames = response.data.data.rows;
          setGames(listedGames);
        }
      })
      .catch((error) => responseErrorHandler(error, lang));
  };

  const handleChooseCategory = (requestedCategoryId) => {
    setLoadingGames(true);
    let newCategoriesList = categories.map((currentCategory) =>
      currentCategory.id === requestedCategoryId
        ? {
            active: true,
            id: currentCategory.id,
            name_ar: currentCategory.name_ar,
            name_en: currentCategory.name_en,
          }
        : {
            active: false,
            id: currentCategory.id,
            name_ar: currentCategory.name_ar,
            name_en: currentCategory.name_en,
          }
    );
    setCategories(newCategoriesList);
    if (requestedCategoryId === 0) {
      listAllGames();
    } else {
      getGamesByCategory(requestedCategoryId);
    }
  };

  useEffect(() => {
    listAllGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar setSidebarOpen={setSidebarOpen} />
      {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
      <main className="flex flex-col flex-1 lg:py-16 bg-gaming">
        <div className="flex flex-col px-8 md:px-24 mt-24 gradient-bg">
          <h2 className="text-xl font-normal text-white">
            {lang === "en" ? "All Games" : "جميع الألعاب"}
          </h2>
          {loadingGames ? (
            <LoadingIcon />
          ) : (
            <div className="flex flex-row justify-between items-center">
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    {lang === "en" ? "Select a category" : "إختر تصنيف"}
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full ltr:pl-3 ltr:pr-10 rtl:pr-3 rtl:pl-10 py-2 text-base border-dark focus:outline-none focus:ring-shilail-600 focus:border-shilail-500 sm:text-sm rounded-md"
                    defaultValue={() => {
                      return categories.find((category) => category.active).id;
                    }}
                    onChange={(selectedValue) =>
                      handleChooseCategory(selectedValue.target.value)
                    }
                    autoComplete="off"
                  >
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {lang === "en" ? category.name_en : category.name_ar}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav
                    className="-mb-px flex space-x-8 rtl:space-x-reverse"
                    aria-label="Tabs"
                  >
                    {/* <button
                  type="button"
                  onClick={() => changeCategory("All")}
                        className={classNames(
                          category.current
                            ? "border-shilail-400 text-shilail-500"
                            : "border-transparent text-gray-500 hover:text-dark hover:border-dark",
                          "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        )}
                        aria-current={category.current ? "page" : undefined}
                      >
                        {lang === 'en' ? category.name_en : category.name_ar}
                      </button> */}
                    {categories.map((category) => (
                      <button
                        type="button"
                        key={category.id}
                        onClick={() => handleChooseCategory(category.id)}
                        className={classNames(
                          category.active
                            ? "border-shilail-400 text-shilail-500"
                            : "border-transparent text-gray-500 hover:text-dark hover:border-dark",
                          "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        )}
                        aria-current={category.active ? "page" : undefined}
                      >
                        {lang === "en" ? category.name_en : category.name_ar}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="px-8 md:px-24 mt-6">
          <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6">
            {!loadingGames &&
              games.map((game, gameIndex) => (
                <Link
                  className="px-2 py-4 bg-dark rounded-md drop-shadow-lg transform transition-all hover:scale-105 hover:drop-shadow-2xl "
                  key={game.id}
                  to={"/game/" + game.id}
                >
                  <div className="space-y-2">
                    <img
                      className="mx-auto h-28 w-40 rounded-md lg:w-48 lg:h-24"
                      src={apiUrl + "/resources/images/games/" + game.image}
                      alt=""
                    />
                    <div className="space-y-2">
                      <div className="text-md text-white">
                        <p className="text-shilail-500 text-xs">
                          {lang === "en" ? game.name_en : game.name_ar}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </main>
      {/* </Sidebar> */}
    </>
  );
}
