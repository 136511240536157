import axios from "axios";
import {
  authHeader,
  authHeaderWithMultipart,
  guestHeader,
} from "../utils/requestHeaders";

const apiUrl = process.env.REACT_APP_API_URL;

export const getFavoriteGames = (userId) => {
  return axios.get(apiUrl + "/favorite/" + userId + "/1/1000", {
    headers: guestHeader(),
  });
};

export const updateProfile = (profile) => {
  // return axios.put(apiUrl + "update-info", { profile }, authHeader);
  let formData = new FormData();
  formData.append("firstName", profile.firstName);
  formData.append("lastName", profile.lastName);
  formData.append("username", profile.username);
  formData.append("bio", profile.bio);
  formData.append("email", profile.email);
  formData.append("team", profile.team);
  formData.append("location", profile.location);
  formData.append("phoneNumber", profile.phoneNumber);
  if (profile.avatar) formData.append("avatar", profile.avatar);
  if (profile.coverPhoto) formData.append("avatar", profile.coverPhoto);
  return axios.put(apiUrl + "/user/update-info", formData, {
    headers: authHeaderWithMultipart(),
  });
};

export const getSubscriptions = () => {
  return axios.get(apiUrl + "/subscription/all/1/1000", {
    headers: authHeader(),
  });
};

export const getPricings = () => {
  return axios.get(apiUrl + "/pricing/all/1/1000", {
    headers: authHeader(),
  });
};
