import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import LoadingIcon from "../../components/LoadingIcon";
import * as profileService from "../../services/profile.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserData, responseErrorHandler } from "../../utils/common";

export default function Favorites() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoadings] = useState(true);
  const [favorites, setFavorites] = useState([]);

  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);

  let navigate = useNavigate();

  useEffect(() => {
    profileService
      .getFavoriteGames(user.id)
      .then((response) => {
        if (response.status === 200) {
          setFavorites(response.data.data.rows);
          setLoadings(false);
        } else if (response.status === 404) {
          setFavorites([]);
          setLoadings(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navbar setSidebarOpen={setSidebarOpen} />
      <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}>
        <div className="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
          <div className="pt-10 pb-16">
            <div className="px-4 sm:px-6 md:px-0">
              <h1 className="text-3xl font-extrabold text-white pb-6">
                {lang === "en" ? "Favorites" : "المفضلة"}
              </h1>
            </div>
            {loading ? (
              <LoadingIcon />
            ) : favorites.length === 0 ? (
              <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg py-6 px-12 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <span className="mt-2 block text-sm font-medium text-gray-400">
                  {lang === "en" ? "No favorite games" : "لا يوجد العاب مفضلة"}
                </span>
              </div>
            ) : (
              favorites.map((favorite) => (
                <div
                  key={favorite.id}
                  className="relative rounded-lg border border-gray-700 bg-dark px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={favorite.image}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <Link to="/" className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-white">
                        {lang === "en" ? favorite.name_en : favorite.name_ar}
                      </p>
                      <p className="text-sm text-white text-opacity-50 truncate">
                        {lang === "en"
                          ? favorite.category.name_en
                          : favorite.category.name_ar}
                      </p>
                    </Link>
                  </div>
                </div>
              ))
            )}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">{}</div>
          </div>
        </div>
      </Sidebar>
    </React.Fragment>
  );
}
