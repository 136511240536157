import React, { useEffect, useState } from "react";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import { UserAddIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingIcon from "../../components/LoadingIcon";
import * as profileService from "../../services/profile.service";
import { clearUserData, responseErrorHandler } from "./../../utils/common";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [favoriteGames, setFavoriteGames] = useState([]);

  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);

  let navigate = useNavigate();

  useEffect(() => {
    profileService
      .getFavoriteGames(user.id)
      .then((response) => {
        if (response.status === 200) {
          setFavoriteGames(response.data.data.rows);
          setLoadingFavorites(false);
        } else if (response.status === 404) {
          setFavoriteGames([]);
          setLoadingFavorites(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navbar setSidebarOpen={setSidebarOpen} />
      <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}>
        <article>
          {/* Profile header */}
          <div>
            <div>
              {user.coverImage ? (
                <img
                  className="h-32 w-full object-cover lg:h-48"
                  src={user.coverImage}
                  alt=""
                />
              ) : (
                <img
                  className="h-32 w-full object-cover lg:h-48"
                  src={
                    "https://via.placeholder.com/1950x1300?text=" +
                    user.firstName +
                    user.lastName
                  }
                  alt=""
                />
              )}
            </div>
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                <div className="flex">
                  {user.avatar ? (
                    <img
                      className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                      src={user.avatar}
                      alt=""
                    />
                  ) : (
                    <span className="inline-block h-24 w-24 ring-4 ring-white sm:h-32 sm:w-32 rounded-full overflow-hidden bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                </div>
                <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                  <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                    <h1 className="text-2xl font-bold text-white truncate">
                      {user.firstName + " " + user.lastName}
                    </h1>
                  </div>
                  <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                    {/* <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    >
                      <MailIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Message</span>
                    </button> */}
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    >
                      <UserAddIcon
                        className="ltr:-ml-1 ltr:mr-2 rtl:-mr-1 rtl:ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>
                        {lang === "en" ? "Add friend" : "إضافة كـ صديق"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                <h1 className="text-2xl font-bold text-white truncate">
                  {user.firstName + " " + user.lastName}
                </h1>
              </div>
            </div>
          </div>

          {/* Description list */}
          <div className="mt-8 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {user.phoneNumber && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-white text-opacity-50">
                    {lang === "en" ? "Phone number" : "رقم الهاتف"}
                  </dt>
                  <dd className="mt-1 text-sm text-white">
                    {user.phoneNumber}
                  </dd>
                </div>
              )}

              {user.email && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-white text-opacity-50">
                    {lang === "en" ? "Email address" : "البريد الإلكتروني"}
                  </dt>
                  <dd className="mt-1 text-sm text-white">{user.email}</dd>
                </div>
              )}

              {user.team && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-white text-opacity-50">
                    {lang === "en" ? "Team" : "الفريق"}
                  </dt>
                  <dd className="mt-1 text-sm text-white">{user.team}</dd>
                </div>
              )}

              {user.location && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-white text-opacity-50">
                    {lang === "en" ? "Location" : "المكان"}
                  </dt>
                  <dd className="mt-1 text-sm text-white">{user.location}</dd>
                </div>
              )}

              {user.bio && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-white text-opacity-50">
                    {lang === "en" ? "About" : "نبذة"}
                  </dt>
                  <dd className="mt-1 max-w-prose text-sm text-white space-y-5">
                    <p>{user.bio}</p>
                  </dd>
                </div>
              )}
            </dl>
          </div>

          {/* Team member list */}
          <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
            {loadingFavorites ? (
              <LoadingIcon />
            ) : favoriteGames.length === 0 ? (
              <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg py-6 px-12 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <span className="mt-2 block text-sm font-medium text-gray-400">
                  {lang === "en" ? "No favorite games" : "لا يوجد العاب مفضلة"}
                </span>
              </div>
            ) : (
              <>
                <h2 className="text-sm font-medium text-gray-500">
                  {lang === "en" ? "Favorite games" : "الالعاب المفضلة"}
                </h2>
                <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {favoriteGames.map((favoriteGame) => (
                    <div
                      key={favoriteGame.id}
                      className="relative rounded-lg border border-gray-700 bg-dark px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
                    >
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={favoriteGame.image}
                          alt=""
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <Link to="/" className="focus:outline-none">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          <p className="text-sm font-medium text-white">
                            {lang === "en"
                              ? favoriteGame.name_en
                              : favoriteGame.name_ar}
                          </p>
                          <p className="text-sm text-white text-opacity-50 truncate">
                            {lang === "en"
                              ? favoriteGame.category.name_en
                              : favoriteGame.category.name_ar}
                          </p>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </article>
      </Sidebar>
    </React.Fragment>
  );
}
