import axios from "axios";
import { authHeader, guestHeader } from "../utils/requestHeaders";

const apiUrl = process.env.REACT_APP_API_URL;

export const listGames = () => {
  return axios.get(apiUrl + "/game/all/1/1000", {
    headers: guestHeader(),
  });
};

export const listGamesByCategory = (categoryId) => {
  return axios.get(apiUrl + "/game/in-category/" + categoryId + "/1/1000", {
    headers: guestHeader(),
  });
};

export const listMostPlayedGames = () => {
  return axios.get(apiUrl + "/game/most-played", {
    headers: guestHeader(),
  });
};

export const getGame = (gameId) => {
  return axios.get(apiUrl + "/game/" + gameId, {
    headers: guestHeader(),
  });
};

export const playGame = (gameId) => {
  return axios.get(apiUrl + "/game/play/" + gameId, {
    headers: guestHeader(),
  });
};

export const searchGames = (keyword) => {
  return axios.get(apiUrl + "/game/search/" + keyword + "/1/1000", {
    headers: guestHeader(),
  });
};

export const getCategories = () => {
  return axios.get(apiUrl + "/category/all/1/1000", {
    headers: guestHeader(),
  });
};
