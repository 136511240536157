import axios from "axios";
import { authHeader, guestHeader } from "../utils/requestHeaders";

const apiUrl = process.env.REACT_APP_API_URL;

export const login = (phoneNumber, password) => {
  return axios.post(
    apiUrl + "/auth/login",
    { phoneNumber, password },
    {
      headers: guestHeader(),
    }
  );
};

export const changePassword = (password, newPassword) => {
  return axios.post(
    apiUrl + "/auth/change-password",
    {
      password,
      newPassword,
    },
    {
      headers: authHeader(),
    }
  );
};

export const register = (
  // firstName,
  // lastName,
  // username,
  phoneNumber,
  // email,
  password
) => {
  return axios.post(
    apiUrl + "/auth/register",
    { phoneNumber, password },
    {
      headers: guestHeader(),
    }
  );
};

export const checkPhoneNumber = (phoneNumber) => {
  return axios.get(apiUrl + "/auth/phone-number/is-taken/" + phoneNumber, {
    headers: guestHeader(),
  });
};
