import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../../partials/navbar";
// import Sidebar from "../../partials/sidebar";
// import { classNames } from "../../utils/common";
import Hero from "./../../components/Hero/Hero";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as gameServices from "./../../services/game.service";
import LoadingIcon from "../../components/LoadingIcon";
import { useLocation } from "react-router-dom";
import { responseErrorHandler } from "../../utils/common";
import Footer from "../../partials/footer";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper";
import * as subscribeActions from "../../redux/actions/subscribeActions";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SearchIcon, SparklesIcon } from "@heroicons/react/solid";

export default function HomePage({ msisdn }) {
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const [searching, setsearching] = useState(false);
  // const [games, setGames] = useState([]);
  const [mostPlayedGames, setMostPlayedGames] = useState([]);
  const [categories, setCategories] = useState([]);

  // const [slidesPerView, setSlidesPerView] = useState(3);
  // const [spaceBetweenSlides, setSpaceBetweenSlides] = useState(10);

  const lang = useSelector((state) => state.app.lang);
  const subscription = useSelector((state) => state.subscription);
  const [games, setGames] = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_URL;

  const listMostPlayedGames = () => {
    gameServices
      .listMostPlayedGames()
      .then((response) => {
        if (response.status === 200) {
          let listedGames = response.data.data;
          setMostPlayedGames(listedGames);
        }
      })
      .catch((error) => responseErrorHandler(error, lang));
  };

  const listAllGames = () => {
    gameServices
      .getCategories()
      .then((response) => {
        setLoadingGames(false);
        setCategories(response.data.data.rows);
      })
      .catch((error) => responseErrorHandler(error, lang));
  };

  useEffect(() => {
    listAllGames();
    listMostPlayedGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    let keyword = e.target.value;
    console.log(keyword);
    if (keyword !== "") {
      setsearching(true);
      gameServices
        .searchGames(keyword)
        .then((response) => {
          setLoadingGames(false);
          if (response.status === 200) {
            let listedGames = response.data.data.rows;
            setGames(listedGames);
          }
        })
        .catch((error) => responseErrorHandler(error, lang));
    } else {
      setsearching(false);
    }
  };

  return (
    <>
      <Navbar />
      <main className="flex flex-col justify-center flex-1 lg:py-16 bg-gaming">
        <Hero />
        <div>
          {subscription.subscription === null ? (
            <div className="bg-dark/25 px-4 py-6 mx-auto max-w-4xl w-full rounded-lg backdrop-blur-lg shadow-lg">
              <h2 className="text-xl font-normal text-white text-center">
                {lang === "en" ? "Subscribe now" : "اشترك الآن"}
              </h2>
              <p className="text-sm font-normal text-white text-center">
                {lang === "en"
                  ? "Subscribe to enjoy latest games."
                  : "اشترك في منصة شليل للتمتع باحدث الألعاب."}
              </p>

              <Link
                to="/subscribe"
                className="mt-3 w-full justify-center relative mx-1 tracking-wide inline-flex items-center px-4 py-3 border border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 "
              >
                <div className="relative">
                  <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1" />
                  <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1 animate-ping absolute top-0" />
                </div>
                <span>{lang === "en" ? "Subscribe now" : "إشترك الان"}</span>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className=" pb-2 mx-auto max-w-4xl w-full mt-4 rounded-lg">
          <form className="w-full">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 ltr:pl-3 rtl:pr-3 flex items-center">
                <SearchIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="keyword"
                className="block w-full bg-darker/25 border-transparent rounded-lg py-2 ltr:pl-10 rtl:pr-10 ltr:pr-3 rtl:pl-3 text-white text-sm focus:border-gray-500 focus:ring-gray-500 placeholder-white sm:text-sm "
                placeholder={lang === "en" ? "Search" : "بحث"}
                type="text"
                onChange={handleSearch}
              />
            </div>
          </form>
        </div>

        {searching === true ? (
          <div className="bg-dark/75 pb-6 mx-auto max-w-4xl w-full mt-4 rounded-lg backdrop-blur-lg shadow-lg">
            <div className="flex flex-col mb-2 bg-shilail-500 rounded-t-md h-12 w-full">
              <h2 className="py-2 text-xl font-bold text-white mr-3">
                {lang === "en" ? "Search results" : "نتائج البحث"}
              </h2>
            </div>
            <div className="mt-6">
              {loadingGames ? (
                <LoadingIcon />
              ) : games && games.length > 0 ? (
                <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:gap-x-8 lg:gap-y-12 xl:grid-cols-3">
                  {games.map((game) => (
                    <Link
                      className="bg-dark/25 backdrop-blur-lg rounded-md drop-shadow-lg transform transition-all w-full "
                      key={game.id}
                      to={
                        subscription.subscription
                          ? `/game/${game.id}/play`
                          : "/subscribe"
                      }
                    >
                      <div className="space-y-2">
                        <img
                          className="mx-auto h-20 w-40 rounded-md lg:w-48 lg:h-24"
                          src={apiUrl + "/resources/images/games/" + game.image}
                          alt=""
                        />
                        <div className="space-y-2">
                          <div className="text-md text-white">
                            <p className="text-shilail-500 text-xs">
                              {lang === "en"
                                ? game.category.name_en
                                : game.category.name_ar}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="pb-6 mx-auto max-w-4xl w-full mt-4 rounded-lg ">
                  <center>
                    <p className="w-full text-align:center text-white">
                      {lang === "en"
                        ? "No results found"
                        : "لم يتم العثور على أي نتائج"}
                    </p>
                  </center>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {mostPlayedGames && mostPlayedGames.length > 0 && (
              <div className="bg-dark/75 px-4 py-6 mx-auto max-w-4xl w-full mt-4 rounded-lg backdrop-blur-lg shadow-lg">
                <div className="flex flex-col mb-2">
                  <h2 className="text-xl font-normal text-white">
                    {lang === "en" ? "Most Played" : "الأكثر شعبية"}
                  </h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {mostPlayedGames.map(
                    (mostPlayedGame, mostPlayedGameIndex) => (
                      <Link
                        to={
                          subscription.subscription
                            ? `/game/${mostPlayedGame.game.id}/play`
                            : "/subscribe"
                        }
                        className={
                          "bg-dark/25 backdrop-blur-lg rounded-md drop-shadow-lg transform transition-all w-full " +
                          (mostPlayedGames.length > 4 &&
                          (mostPlayedGameIndex === 0 ||
                            (((mostPlayedGameIndex + 3) % 10 === 0 ||
                              (mostPlayedGameIndex - 3) % 10 === 0) &&
                              (mostPlayedGameIndex - 3) % 10 !== 0) ||
                            mostPlayedGameIndex % 10 === 0)
                            ? "md:col-span-2 md:row-span-2"
                            : "")
                        }
                        key={mostPlayedGame.game.id}
                      >
                        <div className="relative h-full w-full">
                          <img
                            className="h-full w-full rounded-md"
                            src={
                              apiUrl +
                              "/resources/images/games/" +
                              mostPlayedGame.game.image
                            }
                            alt=""
                          />
                          <p className="text-white text-md line-clamp-1 absolute bottom-0 w-full text-center pb-4 pt-2 bg-gradient-to-t from-black to-black/0 rounded-b-lg">
                            {lang === "en"
                              ? mostPlayedGame.game.name_en
                              : mostPlayedGame.game.name_ar}
                          </p>
                        </div>
                      </Link>
                    )
                  )}
                </div>
              </div>
            )}
            {loadingGames ? (
              <LoadingIcon />
            ) : (
              categories.length > 0 &&
              categories.map((category, categoryIdx) => {
                if (category.games.length === 0) return null;
                return (
                  <div
                    key={categoryIdx.toString()}
                    className="bg-dark/75 pb-6 mx-auto max-w-4xl w-full mt-4 rounded-lg backdrop-blur-lg shadow-lg"
                  >
                    <div className="flex flex-col mb-2 bg-shilail-500 rounded-t-md h-12 w-full">
                      <h2 className="py-2 text-xl font-bold text-white mr-3">
                        {lang === "en" ? category.name_en : category.name_ar}
                      </h2>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-4">
                      {category.games &&
                        category.games.length > 0 &&
                        category.games.map((game, gameIndex) => (
                          <Link
                            to={
                              subscription.subscription
                                ? `/game/${game.id}/play`
                                : "/subscribe"
                            }
                            className={
                              "bg-dark/25 backdrop-blur-lg rounded-md drop-shadow-lg transform transition-all w-full " +
                              (category.games.length > 4 &&
                              (gameIndex === 0 ||
                                (((gameIndex + 3) % 10 === 0 ||
                                  (gameIndex - 3) % 10 === 0) &&
                                  (gameIndex - 3) % 10 !== 0) ||
                                gameIndex % 10 === 0)
                                ? "md:col-span-2 md:row-span-2"
                                : "")
                            }
                            key={game.id}
                          >
                            <div className="relative h-full w-full">
                              <img
                                className="h-full w-full rounded-md"
                                src={
                                  apiUrl +
                                  "/resources/images/games/" +
                                  game.image
                                }
                                alt=""
                              />
                              <p className="text-white text-md line-clamp-1 absolute bottom-0 w-full text-center pb-4 pt-2 bg-gradient-to-t from-black to-black/0 rounded-b-lg">
                                {lang === "en" ? game.name_en : game.name_ar}
                              </p>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </div>
                );
              })
            )}
          </>
        )}
      </main>
      <Footer />
    </>
  );
}
