import React, { useState, useEffect } from "react";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import { responseErrorHandler } from "../../utils/common";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as gameServices from "./../../services/game.service";
import LoadingIcon from "../../components/LoadingIcon";

export default function Search() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const [games, setGames] = useState([]);

  const lang = useSelector((state) => state.app.lang);
  const subscription = useSelector((state) => state.subscription);

  const [searchParams] = useSearchParams();
  const searchKeyword = searchParams.get("keyword");

  const apiUrl = process.env.REACT_APP_API_URL;

  const getSearchResults = (keyword) => {
    gameServices
      .searchGames(keyword)
      .then((response) => {
        setLoadingGames(false);
        if (response.status === 200) {
          let listedGames = response.data.data.rows;
          setGames(listedGames);
        }
      })
      .catch((error) => responseErrorHandler(error, lang));
  };

  useEffect(() => {
    if (searchKeyword !== "") {
      getSearchResults(searchKeyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  useEffect(() => {
    if (searchKeyword !== "") {
      getSearchResults(searchKeyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar setSidebarOpen={setSidebarOpen} />
      {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
      <main className="flex flex-col flex-1 lg:py-16 bg-gaming">
        <div className="px-4 py-6 mx-2 mt-4 rounded-lg backdrop-blur-lg shadow-lg bg-dark/25">
          <div className="flex flex-col ">
            <h2 className="text-xl font-normal text-white">
              {lang === "en" ? "Search results" : "نتائج البحث"}
            </h2>
          </div>
          <div className="mt-6">
            {loadingGames ? (
              <LoadingIcon />
            ) : (
              <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6">
                {games &&
                  games.length > 0 &&
                  games.map((game) =>
                    subscription.subscription !== null ? (
                      <Link
                        // className="px-2 py-4 bg-dark rounded-md drop-shadow-lg transform transition-all hover:scale-105 hover:drop-shadow-2xl "
                        className="bg-dark/25 backdrop-blur-lg rounded-md drop-shadow-lg transform transition-all w-full "
                        key={game.id}
                        to={"/game/" + game.id}
                      >
                        <div className="space-y-2">
                          <img
                            className="mx-auto h-20 w-40 rounded-md lg:w-48 lg:h-24"
                            src={
                              apiUrl + "/resources/images/games/" + game.image
                            }
                            alt=""
                          />
                          <div className="space-y-2">
                            <div className="text-md text-white">
                              <p className="text-shilail-500 text-xs">
                                {lang === "en"
                                  ? game.category.name_en
                                  : game.category.name_ar}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <a
                        href="http://test.zaindsp.com:3033/?p=6491519963"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-dark/25 backdrop-blur-lg rounded-md drop-shadow-lg transform transition-all w-full "
                        // className="mx-auto h-20 w-40 rounded-md lg:w-48 lg:h-24"
                        key={game.id}
                      >
                        <div className="relative h-full w-full">
                          <img
                            className="h-full w-full rounded-md"
                            src={
                              apiUrl + "/resources/images/games/" + game.image
                            }
                            alt=""
                          />
                          <p className="text-white text-md line-clamp-1 absolute bottom-0 w-full text-center pb-4 pt-2 bg-gradient-to-t from-black to-black/0 rounded-b-lg">
                            {lang === "en" ? game.name_en : game.name_ar}
                          </p>
                        </div>
                      </a>
                    )
                  )}
              </div>
            )}
          </div>
        </div>
      </main>
      {/* </Sidebar> */}
    </>
  );
}
