import React from "react";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { Link } from "react-router-dom";

export default function Hero() {
  const slides = [
    {
      image: "./assets/images/B.png",
      url: "/game/87",
    },
    {
      image: "./assets/images/C.png",
      url: "/game/86",
    },
    {
      image: "./assets/images/A.png",
      url: "/game/78",
    },
  ];
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    arrows: false,
  };

  return (
    <div className="mx-auto max-w-4xl w-full my-2 rounded-3xl shadow-md">
      <Slide {...properties}>
        {slides.map((slide, index) => (
          <Link to={slide.url} key={index} className="each-slide rounded-3xl">
            <img src={slide.image} alt="" className="rounded-3xl" />
          </Link>
        ))}
      </Slide>
    </div>
  );
}
