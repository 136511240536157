import React, { Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { cancelSubscription } from "../../services/subscribe.service";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";
import { updateUser } from "../../redux/actions/authActions";
import { clearUserData, responseErrorHandler } from "./../../utils/common";
import { useNavigate } from "react-router-dom";

export default function Subscriptions() {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [submittingCancelSubscription, setSubmittingCancelSubscription] =
    useState(false);

  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const cancelButtonRef = useRef(null);

  const handleCancelSubscription = () => {
    setSubmittingCancelSubscription(true);
    cancelSubscription()
      .then((response) => {
        if (response.status === 200) {
          let updatedUser = user;
          updatedUser.subscriptions = [];
          dispatch(updateUser(updatedUser));
          setSubmittingCancelSubscription(false);
          setCancelModalOpen(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          let updatedUser = user;
          updatedUser.subscriptions = [];
          dispatch(updateUser(updatedUser));
          setSubmittingCancelSubscription(false);
          setCancelModalOpen(false);
        } else if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  return (
    <>
      <Transition.Root show={cancelModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setCancelModalOpen}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-dark rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-100"
                    >
                      {lang === "en" ? "Cancel Subscription" : "إلغاء الإشتراك"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        {lang === "en"
                          ? "After cancelation you will not be able to play games!"
                          : "بعد إلغاء الإشتراك لن تتمكن من اللعب في شليل!"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                    onClick={() => handleCancelSubscription()}
                  >
                    {submittingCancelSubscription ? (
                      <LoadingButtonIcon />
                    ) : lang === "en" ? (
                      "Cancel Subscription"
                    ) : (
                      "إلغاء اللإشتراك"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCancelModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {submittingCancelSubscription ? (
                      <LoadingButtonIcon />
                    ) : lang === "en" ? (
                      "Keep Subscription"
                    ) : (
                      "إبقاء الإشتراك"
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <main className="w-full mx-auto pb-10 mt-6">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          {/* Payment details */}
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
            {/* Plan */}
            <section aria-labelledby="plan-heading">
              <div className="sm:overflow-hidden">
                <div className="space-y-6">
                  <div>
                    <h2
                      id="plan-heading"
                      className="text-xl leading-6 font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "Subscriptions" : "الإشتراكات"}
                    </h2>
                    <h5 className="text-sm leading-6 font-medium text-white text-opacity-20">
                      {lang === "en"
                        ? "Subscription informations."
                        : "ادارة بيانات الاشتراكات."}
                    </h5>
                  </div>

                  {/* {loadingPlans ? (
                      <LoadingIcon />
                    ) : plans.length === 0 ? (
                      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg py-6 px-12 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                          />
                        </svg>
                        <span className="mt-2 block text-sm font-medium text-gray-400">
                          No available plans yet.
                        </span>
                      </div>
                    ) : (
                      <RadioGroup
                        value={selectedPlan}
                        onChange={setSelectedPlan}
                      >
                        <RadioGroup.Label className="sr-only">
                          Subscription plans
                        </RadioGroup.Label>
                        <div className="relative bg-dark rounded-md -space-y-px">
                          {plans.map((plan, planIdx) => (
                            <RadioGroup.Option
                              key={plan.name}
                              value={plan}
                              className={({ checked }) =>
                                classNames(
                                  planIdx === 0
                                    ? "rounded-tl-md rounded-tr-md"
                                    : "",
                                  planIdx === plans.length - 1
                                    ? "rounded-bl-md rounded-br-md"
                                    : "",
                                  checked
                                    ? "bg-darker border-primary z-10"
                                    : "border-gray-800",
                                  "relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <div className="flex items-center text-sm">
                                    <span
                                      className={classNames(
                                        checked
                                          ? "bg-primary border-transparent"
                                          : "bg-white border-gray-300",
                                        active
                                          ? "ring-2 ring-offset-2 ring-gray-900"
                                          : "",
                                        "h-4 w-4 rounded-full border flex items-center justify-center"
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <RadioGroup.Label
                                      as="span"
                                      className={classNames(
                                        checked ? "" : "text-opacity-50",
                                        "ml-3 font-medium text-white"
                                      )}
                                    >
                                      {plan.name}
                                    </RadioGroup.Label>
                                  </div>
                                  <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                                    <span
                                      className={classNames(
                                        checked ? "text-primary" : "text-white",
                                        "font-medium"
                                      )}
                                    >
                                      {plan.priceMonthly} / mo
                                    </span>{" "}
                                    <span
                                      className={
                                        checked
                                          ? "text-primary"
                                          : "text-gray-500"
                                      }
                                    >
                                      {plan.priceYearly} / yr
                                    </span>
                                  </RadioGroup.Description>
                                  <RadioGroup.Description
                                    className={classNames(
                                      checked ? "text-white" : "text-gray-500",
                                      "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                                    )}
                                  >
                                    {plan.limit}
                                  </RadioGroup.Description>
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    )} */}
                </div>
                {/* {plans.length > 0 && (
                    <div className="px-4 py-3 text-right sm:px-0 mt-2">
                      <button
                        type="submit"
                        className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Update Plan
                      </button>
                    </div>
                  )} */}
              </div>
            </section>

            {/* Billing history */}
            <section aria-labelledby="billing-history-heading">
              <div className="bg-dark pt-6 shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 sm:px-6">
                  <h2
                    id="billing-history-heading"
                    className="text-lg leading-6 font-medium text-white text-opacity-50"
                  >
                    {lang === "en"
                      ? "Active subscriptions"
                      : "الإشتراكات الحالية"}
                  </h2>
                </div>
                {user.subscriptions.length > 0 ? (
                  <div className="mt-6 flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-t border-gray-800">
                          <table className="min-w-full divide-y divide-gray-800">
                            <thead className="bg-darker">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 ltr:text-left rtl:text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {lang === "en"
                                    ? "Subscribed since"
                                    : "تاريخ الإشتراك"}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 ltr:text-left rtl:text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {lang === "en" ? "Plan" : "الخطة"}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 ltr:text-left rtl:text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {lang === "en" ? "Amount" : "السعر"}
                                </th>
                                {/*
                                  `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                                */}
                                <th
                                  scope="col"
                                  className="relative px-6 py-3 ltr:text-left rtl:text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  <span className="sr-only">
                                    {lang === "en"
                                      ? "View receipt"
                                      : "عرض الفاتورة"}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-dark divide-y divide-gray-200">
                              <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white text-opacity-50">
                                  {
                                    user.subscriptions[0].createdAt.split(
                                      "T"
                                    )[0]
                                  }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {lang === "en"
                                    ? user.subscriptions[0].price.name_en
                                    : user.subscriptions[0].price.name_ar}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {user.subscriptions[0].amount}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                  <button
                                    onClick={() => setCancelModalOpen(true)}
                                    type="button"
                                    className="text-white text-opacity-50 hover:text-white hover:text-opacity-80"
                                  >
                                    {lang === "en"
                                      ? "Cancel subscription"
                                      : "إلغاء الإشتراك"}
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-t border-gray-800 p-6 text-white text-opacity-50 text-center">
                          {lang === "en"
                            ? "No active subscriptions"
                            : "لا يوجد اشتراكات حالية"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
}
