import React, { Fragment, useState, useRef } from "react";
import { Dialog, Transition, Menu, Popover } from "@headlessui/react";
import { SearchIcon, SparklesIcon } from "@heroicons/react/solid";
import {
  BellIcon,
  // MenuIcon,
  ExclamationCircleIcon,
  DeviceMobileIcon,
} from "@heroicons/react/outline";
import {
  classNames,
  responseErrorHandler,
  clearUserData,
} from "../../utils/common";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  authenticatedNavigationEn,
  authenticatedNavigationAr,
  userNavigationEn,
  userNavigationAr,
  subscribedNavigationEn,
  subscribedNavigationAr,
} from "../../utils/navigation";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../redux/actions/authActions";
import * as subscribeActions from "../../redux/actions/subscribeActions";
import { cancelSubscription } from "../../services/subscribe.service";
import { langToAr, langToEn } from "./../../redux/actions/appActions";
import LoadingButtonIcon from "./../../components/LoadingButtonIcon";
import logoAr from "../../assets/img/Logo-Ar-Colored.png";
import logoEn from "../../assets/img/Logo-En-Colored.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

export default function Navbar() {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [submittingCancelSubscription, setSubmittingCancelSubscription] =
    useState(false);

  const SearchSchema = Yup.object().shape({
    keyword: Yup.string()
      .trim()
      .min(2, "First name must be 4 characters at minimum")
      .required("First name is required"),
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.app.lang);
  const subscription = useSelector((state) => state.subscription);
  const [modalOpen, setModalOpen] = useState(false);
  const [submittingPhoneNumber, setSubmittingPhoneNumber] = useState(false);

  const cancelButtonRef = useRef(null);

  let navigate = useNavigate();

  const handleChnageLang = () => {
    if (lang === "ar") {
      // document.documentElement.dir = "ltr";
      // document.documentElement.lang = "en";
      dispatch(langToEn());
    } else {
      // document.documentElement.dir = "rtl";
      // document.documentElement.lang = "ar";
      dispatch(langToAr());
    }
  };

  const handleCancelSubscription = () => {
    setSubmittingCancelSubscription(true);
    cancelSubscription(subscription.subscription.phoneNumber)
      .then((response) => {
        if (response.status === 200) {
          dispatch(subscribeActions.logout());
          setSubmittingCancelSubscription(false);
          setCancelModalOpen(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setSubmittingCancelSubscription(false);
          setCancelModalOpen(false);
        } else if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  return (
    <>
      <Transition.Root show={cancelModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setCancelModalOpen}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-dark rounded-lg px-4 pt-5 pb-4 ltr:text-left rtl:text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-100"
                    >
                      {lang === "en" ? "Cancel Subscription" : "إلغاء الاشتراك"}{" "}
                      {subscription.subscription &&
                        subscription.subscription.phoneNumber}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        {lang === "en"
                          ? "After cancelation you will not be able to play games!"
                          : "لن تتمكن من تصفح الألعاب بعد إلغاء الاشتراك!"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                    onClick={() => handleCancelSubscription()}
                  >
                    {submittingCancelSubscription ? (
                      <LoadingButtonIcon />
                    ) : lang === "en" ? (
                      "Cancel Subscription"
                    ) : (
                      "إلغاء الاشتراك"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCancelModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {lang === "en" ? "Keep Subscription" : "إبقاء الإشتراك"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-dark/50 backdrop-blur-lg h-16 shadow-lg relative lg:fixed lg:w-full lg:overflow-y-visible z-50"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center h-full">
              <div className="relative flex justify-between w-full">
                <div className="flex xl:col-span-2">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/" className="flex flex-row items-center">
                      <img
                        className="block h-8 w-auto"
                        src={lang === "en" ? logoEn : logoAr}
                        alt={lang === "en" ? "Shilail Games" : "شليل للألعاب"}
                      />
                      <div className="flex flex-col justify-start ltr:pl-2 rtl:pr-2 ltr:ml-2 rtl:mr-2 ltr:border-l rtl:border-r border-gray-500">
                        <img
                          className="block h-6 w-auto mt-0.5"
                          src="/assets/logo/zain-logo.png"
                          alt={lang === "en" ? "Zain - KSA" : "زين - السعودية"}
                        />
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="flex items-center justify-end flex-1">
                  {/* Language switcher */}
                  <button
                    type="button"
                    className="hidden sm:block inline-flex items-center px-3 py-2 border border-gray-800 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                    onClick={() => handleChnageLang()}
                  >
                    {lang === "ar" ? "English" : "العربية"}
                  </button>
                  {/* End Language switcher */}

                  <>
                    {subscription.subscription === null ? (
                      <button
                        className="w-full sm:w-auto justify-center relative mx-1 tracking-wide inline-flex items-center px-8 py-2  border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 relative"
                        onClick={() => navigate("/subscribe")}
                      >
                        <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1 animate-ping absolute" />
                        {lang === "en" ? "Subscribe now" : "الإشتراك"}
                      </button>
                    ) : (
                      <button
                        onClick={() => setCancelModalOpen(true)}
                        type="button"
                        className="w-full sm:w-auto justify-center relative mx-1 tracking-wide inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 relative"
                      >
                        {lang === "en"
                          ? "Cancel Subscription"
                          : "إلغاء الاشتراك"}
                      </button>
                    )}
                  </>
                </div>
              </div>
            </div>
          </>
        )}
      </Popover>
    </>
  );
}
