import React, { useState, useEffect } from "react";
import Navbar from "../../partials/navbar";
// import Sidebar from "../../partials/sidebar";
import { useSelector } from "react-redux";
import Footer from "../../partials/footer";

export default function About() {
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  const lang = useSelector((state) => state.app.lang);

  useEffect(() => {}, []);

  return (
    <>
      <Navbar />
      {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
      <div className="flex flex-col px-8 md:px-24 pt-24 bg-gaming">
        <div className="bg-dark/25 px-4 py-6 mx-2 md:mx-24 mt-4 rounded-lg backdrop-blur-lg shadow-lg">
          <h2 className="text-xl font-normal text-white">
            {lang === "en" ? "About Shilai" : "عن شليل"}
          </h2>
          <p className="text-gray-100 text-sm mt-3">
            {lang === "en"
              ? "Shilail is a Fully Managed And Hosted Game & entertainment platform for Sudanese Youth."
              : "شليل هي منصة مدارة بالكامل لاستضافة الألعاب الترفيهية للشباب السوداني."}
          </p>
          <h2 className="text-xl font-normal text-white mt-6">
            {lang === "en" ? "Information" : "المعلومات"}
          </h2>
          <p className="text-gray-100 text-sm mt-3">
            {lang === "en"
              ? "Shilail is a daily subscription based web app service. Members need to be subscribed to enjoy the service. The service provides unlimited entertainment on your Mobile."
              : "شليل هو تطبيق ويب باشتراك يومي. يتوجب على الأعضاء الإشتراك للتمتع بالخدمة. الخدمة توفر وصول غير محدود للألعاب على هاتفك المحمول."}
          </p>
        </div>
      </div>
      {/* </Sidebar> */}
      <Footer />
    </>
  );
}
