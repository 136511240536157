import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../utils/common";

export default function LoadingIcon() {
  return (
    <div
      className="flex items-center justify-center"
      style={{ minHeight: 150 }}
    >
      <SVG
        className="animate-spin"
        src={toAbsoluteUrl("/assets/loading.svg")}
        style={{
          width: 50,
          height: 50,
        }}
      />
    </div>
  );
}
