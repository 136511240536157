const langToArAction = () => ({ type: "LANG_AR" });
const langToEnAction = () => ({ type: "LANG_EN" });

export const langToAr = () => (dispatch) => {
  dispatch(langToArAction());
};

export const langToEn = () => (dispatch) => {
  dispatch(langToEnAction());
};
