import React, { useState, useEffect } from "react";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import { useSelector } from "react-redux";

export default function Help() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const lang = useSelector((state) => state.app.lang);

  useEffect(() => {}, []);

  return (
    <>
      <Navbar setSidebarOpen={setSidebarOpen} />
      {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
      <main className="flex flex-col flex-1 lg:pt-16 bg-gaming">
        <div className="flex flex-col px-8 md:px-24 mt-24 gradient-bg">
          <h2 className="text-xl font-normal text-white">
            {lang === "en" ? "Help" : "المساعدة"}
          </h2>
          <p className="text-gray-600 text-sm mt-3"></p>
        </div>
      </main>
      {/* </Sidebar> */}
    </>
  );
}
