import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  SUPPORTED_IMAGE_FORMATS,
  MAX_AVATAR_FILE_SIZE,
  MAX_COVERPHOTO_FILE_SIZE,
  responseErrorHandler,
  clearUserData,
} from "../../utils/common";
import * as profileService from "../../services/profile.service";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";
import PreviewAvatarImage from "../../components/PreviewAvatarImage";
import PreviewCoverPhotoImage from "../../components/PreviewCoverPhotoImage";
import { updateUser } from "../../redux/actions/authActions";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const disptach = useDispatch();
  let navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);

  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .min(2, "First name must be 4 characters at minimum")
      .required("First name is required"),
    lastName: Yup.string()
      .trim()
      .min(2, "Last name must be 2 characters at minimum")
      .required("Last name is required"),
    username: Yup.string()
      .min(2, "Username must be 2 characters at minimum")
      .trim()
      .required("Username is required"),
    bio: Yup.string().min(2, "About must be 2 characters at minimum").trim(),
    email: Yup.string()
      .email("Email must be in a valid format")
      .required("Email address is required"),
    phoneNumber: Yup.string()
      .matches(/^(([1]\d{8})|([9]\d{8}))$/, "Phone number is not valid")
      .required("Phone number is required"),
    team: Yup.string().min(2, "Team must be 2 characters at minimum").trim(),
    location: Yup.string()
      .min(2, "Location must be 2 characters at minimum")
      .trim(),
    avatar: Yup.mixed()
      .test("fileSize", "File is too large.", (value) =>
        value ? value && value.size <= MAX_AVATAR_FILE_SIZE : true
      )
      .test("fileType", "Unsupoorted file format.", (value) =>
        value
          ? !value || (value && SUPPORTED_IMAGE_FORMATS.includes(value?.type))
          : true
      ),
    coverPhoto: Yup.mixed()
      .test("fileSize", "File is too large.", (value) =>
        value ? value.size <= MAX_COVERPHOTO_FILE_SIZE : true
      )
      .test("fileType", "Unsupoorted file format.", (value) =>
        value
          ? !value || (value && SUPPORTED_IMAGE_FORMATS.includes(value?.type))
          : true
      ),
  });

  const handleSubmit = (values) => {
    setSubmitting(true);
    profileService
      .updateProfile(values)
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200) {
          let updatedUser = response.data.data;
          updatedUser.subscriptions = user.subscriptions;
          disptach(updateUser(updatedUser));
          setSuccessMessage(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  return (
    <React.Fragment>
      {/* Main content */}
      <div className="flex-1 xl:overflow-y-auto">
        <div className="mx-auto">
          <Formik
            initialValues={{
              firstName: user.firstName || "",
              lastName: user.lastName || "",
              username: user.username || "",
              team: user.team || "",
              location: user.location || "",
              bio: user.bio || "",
              email: user.email || "",
              phoneNumber: user.phoneNumber || "",
              avatar: null,
              coverPhoto: null,
            }}
            validationSchema={ProfileSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formikProps) => (
              <Form className="mt-6 space-y-8">
                <div>
                  <h2
                    id="plan-heading"
                    className="text-xl leading-6 font-medium text-white text-opacity-50"
                  >
                    {lang === "en" ? "Profile" : "الملف الشخصي"}
                  </h2>
                  <h5 className="text-sm leading-6 font-medium text-white text-opacity-20">
                    {lang === "en"
                      ? "User profile information management"
                      : "ادارة بيانات ملفك الشخصي"}
                  </h5>
                </div>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "First name" : "الإسم الأول"}
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      id="first-name"
                      autoComplete="given-name"
                      className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                        formikProps.touched.firstName &&
                        formikProps.errors.firstName
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "Last name" : "الإسم الأخير"}
                    </label>
                    <Field
                      type="text"
                      name="lastName"
                      id="last-name"
                      autoComplete="family-name"
                      className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                        formikProps.touched.lastName &&
                        formikProps.errors.lastName
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500 border-2"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "Username" : "اسم المستخدم"}
                      Username
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 ltr:rounded-l-md rtl:rounded-r-md border border-r-0 border-gray-700 bg-dark text-gray-300 sm:text-sm">
                        shilail.com/
                      </span>
                      <Field
                        type="text"
                        name="username"
                        id="username"
                        autoComplete="username"
                        className={`flex-1 block w-full min-w-0 rounded-none bg-dark rounded-r-md text-white sm:text-sm ${
                          formikProps.touched.username &&
                          formikProps.errors.username
                            ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                            : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                        }`}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="photo"
                      className={`block text-sm font-medium ${
                        formikProps.touched.avatar && formikProps.errors.avatar
                          ? "text-opacity-100 text-red-600"
                          : "text-opacity-50 text-white"
                      }`}
                    >
                      {lang === "en" ? "Photo" : "الصورة الشخصية"}
                    </label>
                    <div className="mt-1 flex items-center">
                      {formikProps.values.avatar ? (
                        <PreviewAvatarImage file={formikProps.values.avatar} />
                      ) : user.avatar ? (
                        <img
                          className="inline-block h-12 w-12 rounded-full"
                          src={user.avatar}
                          alt=""
                        />
                      ) : (
                        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      )}

                      <div className="ltr:ml-4 rtl:mr-4 flex">
                        <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                          <label
                            htmlFor="avatar"
                            className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                          >
                            <span>{lang === "en" ? "Change" : "تغيير"}</span>
                            <span className="sr-only">
                              {" "}
                              {lang === "en" ? "user photo" : "الصورة الشخصية"}
                            </span>
                          </label>
                          <input
                            id="avatar"
                            name="avatar"
                            type="file"
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                            onChange={(event) => {
                              formikProps.setTouched({
                                ...formikProps.touched,
                                avatar: true,
                              });
                              formikProps.setFieldValue(
                                "avatar",
                                event.target.files[0]
                              );
                            }}
                          />
                        </div>
                        {formikProps.values.avatar && (
                          <button
                            type="button"
                            className="ltr:ml-3 rtl:mr-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-white text-opacity-50 hover:text-red-500 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                            onClick={() => {
                              formikProps.setFieldValue("avatar", null);
                            }}
                          >
                            {lang === "en" ? "Remove" : "حذف"}
                          </button>
                        )}
                      </div>
                    </div>
                    {formikProps.touched.avatar &&
                      formikProps.errors.avatar && (
                        <div className="block text-sm text-red-600">
                          {formikProps.errors.avatar}
                        </div>
                      )}
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      className={`block text-sm font-medium ${
                        formikProps.touched.coverPhoto &&
                        formikProps.errors.coverPhoto
                          ? "text-red-600 text-opacity-100"
                          : "text-white text-opacity-50"
                      }`}
                    >
                      {lang === "en" ? "Cover photo" : "صورة الملف الشخصي"}
                    </label>
                    <div
                      className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md ${
                        formikProps.touched.coverPhoto &&
                        formikProps.errors.coverPhoto
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    >
                      <div className="space-y-1 text-center">
                        {formikProps.values.coverPhoto ? (
                          <PreviewCoverPhotoImage
                            file={formikProps.values.coverPhoto}
                          />
                        ) : (
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}

                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="coverPhoto"
                            className="relative cursor-pointer bg-dark rounded-md font-medium text-shilail-500 hover:text-shilail-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-shilail-400"
                          >
                            <span>
                              {lang === "en" ? "Select an image" : "إختر صورة"}
                            </span>
                            <input
                              id="coverPhoto"
                              name="coverPhoto"
                              type="file"
                              className="sr-only"
                              onChange={(event) => {
                                formikProps.setTouched({
                                  ...formikProps.touched,
                                  thumbnail: true,
                                });
                                formikProps.setFieldValue(
                                  "coverPhoto",
                                  event.target.files[0]
                                );
                              }}
                            />
                          </label>
                          <p className="ltr:pl-1 rtl:pr-1">
                            {lang === "en"
                              ? "to use as a cover photo"
                              : "لاستخدامها كـ صورة ملف شخصي"}
                          </p>
                        </div>
                        <p className="text-xs text-gray-500">
                          {formikProps.values.coverPhoto ? (
                            <button
                              className="relative cursor-pointer rounded-md font-medium text-shilail-500 hover:text-shilail-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-shilail-400"
                              onClick={() => {
                                formikProps.setFieldValue("coverPhoto", null);
                              }}
                            >
                              {lang === "en" ? "Remove" : "حذف"}
                            </button>
                          ) : lang === "en" ? (
                            "PNG, JPG, GIF - up to 2MB"
                          ) : (
                            "PNG, JPG, GIF - حتى حجم 2MB"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "About" : "نبذة"}
                    </label>
                    <div className="mt-1">
                      <Field
                        as="textarea"
                        id="description"
                        name="bio"
                        rows={4}
                        className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                          formikProps.touched.bio && formikProps.errors.bio
                            ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                            : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                        }`}
                      />
                    </div>
                    <p className="mt-1 text-xs text-white text-opacity-20">
                      {lang === "en"
                        ? "Brief description about you for your profile."
                        : "نبذة صغيرة عنك ستئهر في ملفك الشخصي."}
                    </p>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="email-address"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "Email address" : "البريد الإلكتروني"}
                    </label>
                    <Field
                      type="text"
                      name="email"
                      id="email-address"
                      autoComplete="email"
                      className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                        formikProps.touched.email && formikProps.errors.email
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "Phone number" : "رقم الهاتف"}
                    </label>
                    <div className="relative rounded-md shadow-sm text-white">
                      <div className="absolute inset-y-0 ltr:left-0 ltr:pl-3 rtl:right-0 rtl:pr-3 flex items-center pointer-events-none">
                        <span className="text-white text-opacity-70 sm:text-sm">
                          +249
                        </span>
                      </div>
                      <Field
                        type="text"
                        name="phoneNumber"
                        id="phone-number"
                        className={`block w-full ltr:pl-16 ltr:sm:pl-12 rtl:pr-16 rtl:sm:pr-12 sm:text-sm rounded-md text-white bg-dark ${
                          formikProps.touched.phoneNumber &&
                          formikProps.errors.phoneNumber
                            ? "focus:ring-red-500 focus:border-red-500 border-red-700"
                            : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                        }`}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="team"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "Team" : "الفريق"}
                    </label>
                    <Field
                      type="text"
                      name="team"
                      id="team"
                      className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                        formikProps.touched.team && formikProps.errors.team
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium text-white text-opacity-50"
                    >
                      {lang === "en" ? "Location" : "المكان"}
                    </label>
                    <Field
                      type="text"
                      name="location"
                      id="location"
                      className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                        formikProps.touched.location &&
                        formikProps.errors.location
                          ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      }`}
                    />
                  </div>
                </div>

                <div className="pt-8 flex justify-end">
                  <button
                    type="submit"
                    className="ltr:ml-3 rtl:mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none disabled:opacity-75"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <LoadingButtonIcon />
                    ) : lang === "en" ? (
                      "Save changes"
                    ) : (
                      "حفظ التعديلات"
                    )}
                  </button>
                </div>

                {successMessage && (
                  <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                      <div className="p-2 rounded-lg bg-shilail-500 shadow-lg sm:p-3">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-shilail-700">
                              <CheckIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="ltr:ml-3 rtl:mr-3 font-medium text-white truncate">
                              <span className="md:hidden">
                                {lang === "en"
                                  ? "Profile updated."
                                  : "تم التحديث."}
                              </span>
                              <span className="hidden md:inline">
                                {lang === "en"
                                  ? "Profile updated! your profile has been successfully updated."
                                  : "تم التحديث! تم تحديث بيانات ملفك الشخصي بنجاح."}
                              </span>
                            </p>
                          </div>
                          <div className="order-2 flex-shrink-0 sm:order-3 ltr:sm:ml-2 rtl:sm:mr-2">
                            <button
                              type="button"
                              className="-mr-1 flex p-2 rounded-md hover:bg-shilail-400 focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setSuccessMessage(false)}
                            >
                              <span className="sr-only">
                                {lang === "en" ? "Dismiss" : "Yothx"}
                              </span>
                              <XIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}
