import React, { useEffect, useState } from "react";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import { playGame } from "./../../services/game.service";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LoadingIcon from "../../components/LoadingIcon";
import { clearUserData, responseErrorHandler } from "./../../utils/common";
import { useNavigate, useLocation } from "react-router-dom";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid";
import { SearchIcon, SparklesIcon } from "@heroicons/react/solid";
import * as subscribeActions from "../../redux/actions/subscribeActions";

export default function GamePlay() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);

  const authenticated = useSelector((state) => state.auth.authenticated);
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);
  const subscription = useSelector((state) => state.subscription);

  let { id } = useParams();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let navigate = useNavigate();
  const dispatch = useDispatch();
  let query = useQuery();

  const gamesUrl = process.env.REACT_APP_GAMES_URL;
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchGame = () => {
    playGame(id)
      .then((response) => {
        if (response.status === 200) {
          setGame(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  const resizeIframe = () => {
    setWindowHeight(window.innerHeight - 64);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    checkSubscriptionStatus();

    if (!game) {
      fetchGame();
    }

    resizeIframe();

    window.addEventListener("resize", resizeIframe);
    return () => window.removeEventListener("resize", resizeIframe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkSubscriptionStatus() {
    if (subscription.subscription === null) {
      dispatch(subscribeActions.logout());
    }
  }

  return (
    <>
      <button
        onClick={() => navigate("/")}
        className="mt-20 absolute left-5 top-0"
      >
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <ArrowCircleLeftIcon className="h-10 w-10 text-amber-400" />
      </button>
      <React.Fragment>
        <Navbar setSidebarOpen={setSidebarOpen} />
        {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
        <main className="flex flex-col flex-1 lg:pt-16 bg-gaming">
          {loading ? (
            <LoadingIcon />
          ) : (
            <iframe
              id="game-iframe"
              height={windowHeight}
              style={{ width: windowWidth }}
              scrolling="no"
              title="fx."
              src={gamesUrl + "/" + game.url}
              frameBorder="no"
              allowFullScreen={true}
            ></iframe>
          )}
          {/* </Sidebar> */}
        </main>
      </React.Fragment>
    </>
  );
}
