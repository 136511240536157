import { combineReducers } from "redux";
import authReducer from "./authReducer";
import subscriptionReducer from "./subscriptionReducer";
import errorReducer from "./errorReducer";
import appReducer from "./appReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  subscription: subscriptionReducer,
  error: errorReducer,
  app: appReducer,
});

export default rootReducer;
