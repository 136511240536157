import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Success() {
  const lang = useSelector((state) => state.app.lang);

  return (
    <div className="bg-dark shadow sm:rounded-lg mt-8">
      <div className="px-4 py-5 sm:p-6 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-12 w-12 mx-auto text-white mb-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
          />
        </svg>
        <h3 className="text-lg leading-6 font-medium text-white">
          {lang === "en" ? "Subscription Confirmed!" : "تم تأكيد الإشتراك!"}
        </h3>
        <div className="mt-2 sm:flex sm:items-center sm:justify-between text-center">
          <div className="w-full text-sm text-white text-opacity-70 text-center">
            <p className="text-center">
              {lang === "en"
                ? "Congratulations! your subscription is active now, you can enjoy playing games."
                : "يمكنك الآن التمتع بالالعاب المتوفرة على منصة شليل."}
            </p>
          </div>
        </div>
        <div className="mt-5 sm:flex-shrink-0 sm:flex sm:items-center text-center">
          <Link
            to="/"
            type="submit"
            className="mx-auto inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 disabled:opacity-10"
          >
            {lang === "en" ? "Browse Games" : "تصفح الألعاب"}
          </Link>
        </div>
      </div>
    </div>
  );
}
